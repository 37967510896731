import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Observable, Subscription } from 'rxjs';

import { BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GuidedTour, GuidedTourService } from 'ngx-guided-tour';

import { ModalWindowConfirmComponent } from '../../views/shared/components';

import { NavData, navItems } from '../navItems';
import { LoggedUser } from '../../views/user/shared/models';
import { Professional } from '../../views/professional/shared/models';
import { Cliente } from '../../views/cliente/shared/models';
import { Empresa } from '../../views/empresa/shared/models';
import { Permissoes } from '../../views/auth/shared/models';
import { layoutTour, tourRouteConfig } from '../app-tour';
import { AssinaturaStatus } from '../../views/home/shared/models';

import { UserService } from '../../views/user/shared';
import { ProfessionalService } from '../../views/professional/shared';
import { ClienteService } from '../../views/cliente/shared';
import { EmpresaService } from '../../views/empresa/shared/services';
import { AuthService } from '../../views/auth/shared';
import { environment } from '../../../environments/environment';
import { AssinaturaService } from '../../views/home/shared';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  @BlockUI() blockui: NgBlockUI;
  logo = this.getLogo();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.logo = this.getLogo();
  }

  public navItems = <NavData[]>[];
  public sidebarMinimized = false;
  private changes: MutationObserver;
  public element: HTMLElement;
  public helpLink = 'https://agendapilates.tawk.help/';
  
  public presencaLink: string = 'https://presenca.agendapilates.com.br';

  linkClicked() {
    console.log('Link foi clicado:', this.presencaLink);
    window.open(this.presencaLink, '_blank')
}


  public anoAtual: Number = (new Date()).getFullYear();

  public loggedUser: LoggedUser;
  public curProfessional: Professional;
  public empresa = {} as Empresa;
  public empresaList = [] as Empresa[];
  public assinaturaStatus = {} as AssinaturaStatus;
  public routePerfil: string[];
  public clientPermissions = {} as Permissoes;
  public clientList$: Observable<Cliente[]>;
  public clientSelected: string;
  public clientLoading: boolean;
  public clientNoResult: boolean;
  public mostrarMenu: boolean = true;

  private aulaOnlineMenuItem = {
    name: 'Aula online',
    url: '/aula-online',
    icon: 'fa fa-play-circle-o',
    class: 'nav-item-aula-online',
    badge: {
      text: 'Novo',
      variant: 'info'
    }
  };
  private routerSubscription: Subscription = new Subscription();

  public layoutTour: GuidedTour = Object.assign({}, layoutTour);
  private tourRouteConfig = Object.assign({}, tourRouteConfig);

  constructor(
    private userService: UserService,
    private professionalService: ProfessionalService,
    private clienteService: ClienteService,
    private empresaService: EmpresaService,
    private authService: AuthService,
    private guideTourService: GuidedTourService,
    private assinaturaService: AssinaturaService,
    private modalService: BsModalService,
    private router: Router,
    @Inject(DOCUMENT) _document?: any
  ) {
    this.authService.findResources().then(() => {
      this.clientPermissions = this.authService.getPermissions('Aluno');
    });
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    this.getLoggedUser();

    this.getSignatureStatus();

    const curEmpresa = this.empresaService.getFromLocalStorage();

    if (curEmpresa) {
      this.empresa = curEmpresa;

      if (this.empresa.utilizaAulaOnline) {
        const nav = Object.assign([], navItems);
        nav.splice(2, 0, this.aulaOnlineMenuItem);
        this.navItems = nav;
      } else {
        this.navItems = Object.assign([], navItems);
      }
    }

    this.empresaList = this.empresaService.getAllFromLocalStorage();

    window.addEventListener('storage', (evt: StorageEvent) => {
      if (evt) {
        if (evt.key == `nuvol-token-empresa-${environment.api.azure.enviroment}` && !!evt.newValue) {
          window.location.reload();
        }
      }
    });
  }

  getLogo(): string {
    return window.innerWidth > 500 ? 'assets/img/nova-logo.svg' : 'assets/icons/icon-agenda.svg';
  }

  toggleMenu(event? : boolean) {
    this.mostrarMenu = !this.mostrarMenu;

    return event ? event : false;
  }

  public seePerfil(): boolean {
    if (this.userService.usuarioLogado?.mostrarPainelInstrutorAulas) {
      this.routePerfil = ['/aulas'];
    } else if (this.userService.usuarioLogado?.mostrarPainelInstrutor) {
      this.routePerfil = ['/comissao'];
    }
    return this.userService.usuarioLogado?.mostrarPainelInstrutor;
  }

  public notification(): boolean {
    return this.userService.usuarioLogado?.noticiasNovas;
  }

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.guideTourService.resetTour();

        if (!localStorage.getItem('tourSkip')) {
          return;
        }

        if (evt.url in this.tourRouteConfig) {
          if (!!localStorage.getItem(`${evt.url}_skipTour`)) {
            return;
          }

          const tour: GuidedTour = this.tourRouteConfig[evt.url];
          tour.completeCallback = tour.skipCallback = () => {
            localStorage.setItem(`${evt.url}_skipTour`, 'true');
          };

          setTimeout(() => {
            this.guideTourService.startTour(tour);
          }, 1500);
        }
      }
    });

    setTimeout(() => {
      if (!!localStorage.getItem('tourSkip')) {
        return;
      }

      this.startTour();
    }, 1300);
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.changes.disconnect();

    this.assinaturaStatus = {} as AssinaturaStatus;
  }

  private getSignatureStatus(): void {
    this.assinaturaService.buscarStatus().subscribe(
      resp => this.assinaturaStatus = resp,
      error => console.log(error.message)
    );
  }

  public startTour(): void {
    this.layoutTour.completeCallback = () => {
      localStorage.setItem('tourSkip', 'true');

      if (window.innerWidth < 992) {
        this.hideSidebar();
      }

      this.router.navigate(['/', 'agenda']);
    };

    if (this.router.url in this.tourRouteConfig) {
      if (window.innerWidth < 992) {
        this.hideSidebar();
      }

      this.guideTourService.startTour(this.tourRouteConfig[this.router.url]);
    } else {
      if (window.innerWidth < 992) {
        document.querySelector('body').classList.add('sidebar-show');
      }

      setTimeout(() => {
        this.guideTourService.startTour(this.layoutTour);
      }, 1500);
    }
  }

  private getLoggedUser() {
    this.userService.getLoggedUser()
      .subscribe(
        user => {
          this.loggedUser = user;
          this.userService.usuarioLogado = user;

          if (user.id > 0) {
            this.getProfessional();
          }
        }, error => {
          console.log(error);
        }
      );
  }

  private getProfessional(): void {
    const url = this.loggedUser.id.toString() + '/true';

    this.professionalService.get(url).subscribe(professional => {
      this.curProfessional = professional;

    });
  }

  public getProfessionalTitle(): string {
    if (this.loggedUser && this.loggedUser.id > 0) {
      return this.loggedUser.cargo;
    } else if (this.loggedUser && this.loggedUser.administrador) {
      return 'Administrador';
    }

    return '';
  }

  public openDialogLogout(): void {
    const modal = this.modalService.show(ModalWindowConfirmComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        contentText: 'Tem certeza que deseja encerrar a sessão atual?'
      }
    });

    modal.content.result.subscribe((res: boolean) => {
      if (res) {
        this.router.navigate(['/', 'auth', 'logout']);
      }
    });
  }

  public typeaheadLoading($event: boolean) {
    this.clientLoading = $event;

    if ($event) {
      this.clientList$ = this.clienteService.getList(this.clientSelected);
    }
  }

  public typeaheadOnSelect(e: TypeaheadMatch): void {
    if (e.item) {
      this.clienteService.setCurItem(e.item);
      this.router.navigate(['/', 'cliente', e.item.id.toString()]);
      this.clientSelected = '';
    }
  }

  typeaheadNoResults(event: boolean): void {
    this.clientNoResult = event;
  }

  private hideSidebar(): void {
    document.querySelector('body').classList.remove('sidebar-show');
  }

  public openWhatsappSignatureChat(): void {
    window.open(`https://wa.me/${this.assinaturaStatus?.telefoneContato}?text=${this.assinaturaStatus?.textoContato}&`, '_blank');
  }

  public hideFooter() {
    this.assinaturaStatus = {} as AssinaturaStatus;
  }

  public getName() {
    const array = this.loggedUser?.nome.split(' ');
    let firstName = "";
    let lastName = "";

    if (array && array.length > 0) {
      firstName = array[0];
      lastName = array[array.length - 1];
    }

    if (lastName != firstName) {
      return `${firstName} ${lastName || ' '}`;
    }

    return `${firstName}`;
  }
}
