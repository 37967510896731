import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html'
})
export class BaseModalComponent {
  @Input() parent: any;
  @Input() dialogTitle: string;
  @Input() icon: string;
  @Input() titleTooltip: {
    enabled: boolean,
    title: string,
    message: string
  };

  public getIcon() {
    return this.icon ? this.icon : 'fa fa-database';
  }

}
