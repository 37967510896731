import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PerfilComissaoComponent } from './perfil/perfil-comissao.component';
import { PermissaoGuardComissao } from './guard/permissaoComissao.guard';
import { PermissaoGuardAulas } from './guard/permissaoAulas.guard';
import { PermissaoService } from './service/permissao.service';
import { ComissaoService } from '../../financeiro/shared/services';
import { AgendaService, RelatorioAulaService } from '../../agenda/shared/services';
import { UserGroupService, UserService } from '../../user/shared';
import { ProfessionalService } from '../shared';
import { PerfilMenuComponent } from './component/perfil-menu.component';
import { PerfilAulasComponent } from './perfil/perfil-aulas.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PerfilAulasComponent,
    PerfilComissaoComponent,
    PerfilMenuComponent
  ],
  providers: [
    UserService,
    ComissaoService,
    PermissaoGuardComissao,
    PermissaoGuardAulas,
    PermissaoService,
    AgendaService,
    UserGroupService,
    RelatorioAulaService,
    ProfessionalService,
  ]
})
export class PerfilModule { }
