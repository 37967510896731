import {NgModule, LOCALE_ID} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localePtBr from '@angular/common/locales/pt';
import {Routes, RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {PopoverModule} from 'ngx-bootstrap/popover';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AlertModule} from 'ngx-bootstrap/alert';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {ptBrLocale} from 'ngx-bootstrap/locale';
import {BsModalRef} from 'ngx-bootstrap/modal';

import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

import {NgxMaskModule} from 'ngx-mask';
import {NgxCurrencyModule} from 'ngx-currency';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgSelectModule} from '@ng-select/ng-select';
import {ChartsModule} from 'ng2-charts';
import {ArchwizardModule} from 'angular-archwizard';

import {
  ListaMatriculasClienteComponent,
  MatriculaFormComponent,
  MatriculaDetalhesComponent,
  CancelaMatriculaComponent,
  MatriculaFormEditComponent,
  TrancaMatriculaComponent,
  NovaMatriculaComponent,
  MatriculaInfoPlanoComponent,
} from '../matriculas/shared/components';

import {
  ListaMensalidadesClienteComponent,
  MensalidadeFormComponent,
  MensalidadeFormAlunoComponent,
  ContaPagarFormComponent,
  TabelaListaAulaComissaoComponent,
  MensalidadeLabelStatusComponent,
  MensalidadeDetalhesComponent,
  MensalidadeDetalhesAlunoComponent
} from '../financeiro/shared/components';
// Importando a versão nova com alias:
import {
  ListaAgendaClientesComponent as ListaAgendaClientesNewComponent,
  AgendaFormComponent as AgendaFormNewComponent,
  AgendaFormEditComponent as AgendaFormEditNewComponent
} from '../agenda/shared/components';

// Importando a versão antiga com alias:
import {
  ListaAgendaClientesComponent as ListaAgendaClientesOldComponent,
  AgendaFormComponent as AgendaFormOldComponent,
  AgendaFormEditComponent as AgendaFormEditOldComponent
} from '../agenda-old/shared/components';
import {ListaReposicaoMatriculaComponent, ListaReposicaoClienteComponent} from '../reposicao/shared/components';
import {ClienteBaseFormComponent} from '../cliente/shared/components';
import {TelefoneBrPipe, CpfBrPipe, FilterPipe, DateAgendaCustomPipe} from './pipes';

import {
  BaseListComponent,
  BaseModalComponent,
  ModalWindowConfirmComponent,
  ModalConfirmOptionalTextComponent,
  DatepickerBsComponent,
  ImageCroppingComponent,
  AlertMessageComponent,
  InputFormComponent,
  ChangeListItemComponent,
} from './components';
import {SelectboxProfessionalIdComponent} from '../professional/shared/components';
import {CarouselModule} from 'ngx-bootstrap/carousel';

const COMPONENTS = [
  ListaMatriculasClienteComponent,
  MatriculaDetalhesComponent,
  MatriculaFormComponent,
  MatriculaFormEditComponent,
  CancelaMatriculaComponent,
  TrancaMatriculaComponent,
  ListaMensalidadesClienteComponent,
  MensalidadeFormComponent,
  MensalidadeFormAlunoComponent,
  MensalidadeLabelStatusComponent,
  ContaPagarFormComponent,
  ListaAgendaClientesNewComponent,
  AgendaFormNewComponent,
  AgendaFormEditNewComponent,
  ListaAgendaClientesOldComponent,
  AgendaFormOldComponent,
  AgendaFormEditOldComponent,
  ListaReposicaoClienteComponent,
  ListaReposicaoMatriculaComponent,
  ClienteBaseFormComponent,
  TabelaListaAulaComissaoComponent,
  BaseModalComponent,
  BaseListComponent,
  DatepickerBsComponent,
  ModalWindowConfirmComponent,
  ModalConfirmOptionalTextComponent,
  ImageCroppingComponent,
  AlertMessageComponent,
  InputFormComponent,
  ChangeListItemComponent,
  NovaMatriculaComponent,
  MatriculaInfoPlanoComponent,
  MensalidadeDetalhesComponent,
  MensalidadeDetalhesAlunoComponent
];

const PROFESSIONAL_COMPONENTS = [
  SelectboxProfessionalIdComponent
];

const PIPERS = [
  TelefoneBrPipe,
  CpfBrPipe,
  FilterPipe,
  DateAgendaCustomPipe
];

const route: Routes = [
  {
    path: 'window-confirm',
    component: ModalWindowConfirmComponent
  },
  {
    path: 'modal-confirm-optional-text',
    component: ModalConfirmOptionalTextComponent
  },
  {
    path: 'alert-message',
    component: AlertMessageComponent
  },
  {
    path: 'image-cropper',
    component: ImageCroppingComponent
  },
  {
    path: 'change-list-item',
    component: ChangeListItemComponent
  }
];

defineLocale('pt-br', ptBrLocale);
registerLocaleData(localePtBr, 'pt-br');

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(route),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ImageCropperModule,
    NgSelectModule,
    ChartsModule,
    NgxMaskModule,
    NgxCurrencyModule,
    ArchwizardModule.forRoot(),
    CarouselModule.forRoot(),
  ],
  declarations: [
    ...COMPONENTS,
    ...PROFESSIONAL_COMPONENTS,
    ...PIPERS,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    PopoverModule,
    BsDatepickerModule,
    NgxMaskModule,
    NgxCurrencyModule,
    TypeaheadModule,
    TabsModule,
    PaginationModule,
    TooltipModule,
    AlertModule,
    AccordionModule,
    CollapseModule,
    BsDropdownModule,
    ImageCropperModule,
    NgSelectModule,
    ChartsModule,
    ...COMPONENTS,
    ...PROFESSIONAL_COMPONENTS,
    ...PIPERS,
    ArchwizardModule
  ],
  providers: [
    DatePipe,
    ...PIPERS,
    {provide: LOCALE_ID, useValue: 'pt-br'},
    BsModalRef
  ]
})
export class SharedModule {}
