import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, RouterLinkWithHref } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-bloqueio-planos',
  templateUrl: './modal-bloqueio-planos.component.html',
  styleUrls: ['./modal-bloqueio-planos.component.css']
})
export class ModalBloqueioPlanosComponent {

  public tituloModal: string;
  public textModal: string;
  private router : Router

  modalRef?: BsModalRef;
  config = {
    animated: true
  };
  constructor(private modalService: BsModalService) {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
  closeModal() {
    return this.modalService.hide()
  }
}
