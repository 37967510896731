import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PermissaoService } from '../service/permissao.service';

@Injectable({
    providedIn: 'root'
})
export class PermissaoGuardAulas implements CanActivate {

    constructor(private permissaoService: PermissaoService, private router: Router) { }

    canActivate(): boolean {
        if (this.permissaoService.temPermissaoAulas()) {
            return true;
        }
        else {
            this.router.navigate(['/perfil']);
            return false;
        }
    }
}
