import { Injectable, Injector } from '@angular/core';

import { CoreService } from '../../../shared/core.service';

import { Matricula } from '../models';

@Injectable()
export class RelatorioMatriculaService extends CoreService<Matricula> {
  protected url = `${this.api.url}relatorioMatricula/`;
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

}
