import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-change-list-item',
  templateUrl: './change-list-item.component.html',
  styleUrls: ['./change-list-item.component.scss']
})
export class ChangeListItemComponent {
  public dialogTitle: string;
  public form: UntypedFormGroup;

  constructor(
    public modal: BsModalRef,
    public modalService: BsModalService,
    private router: Router
  ) {
    this.form = new UntypedFormBuilder().group({
      changeItem: ['changeItem'],
    });

    if (!this.dialogTitle) {
      this.dialogTitle = 'Editar item';
    }

    const subNavigate = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        modal.hide();
        subNavigate.unsubscribe();
      }
    });
  }

  public cancel(): void {
    this.modalService.setDismissReason('cancel');
    this.modal.hide();
  }

  public confirm(): void {
    this.modalService.setDismissReason(this.form.get('changeItem').value);
    this.modal.hide();
  }

}
