import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';

import { Subscription, Observable } from 'rxjs';
import { finalize, delay } from 'rxjs/operators';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';


import { Matricula } from '../../models';
import { IMensalidade } from '../../../../financeiro/shared/models';
import { Permissoes } from '../../../../auth/shared/models';

import { CoreService } from '../../../../shared/core.service';
import { MatriculaService } from '../../services';
import { ClienteService } from '../../../../cliente/shared';
import { UserService } from '../../../../user/shared';
import { AuthService } from '../../../../auth/shared';

import { NovaMatriculaComponent } from '../nova-matricula/nova-matricula.component';
import { ModalWindowConfirmComponent } from '../../../../shared/components';
import { CancelaMatriculaComponent } from '../cancela-matricula/cancela-matricula.component';
import { TrancaMatriculaComponent } from '../tranca-matricula/tranca-matricula.component';

@Component({
  selector: 'app-matricula-detalhes',
  templateUrl: './matricula-detalhes.component.html',
  styleUrls: ['./matricula-detalhes.component.scss'],
  providers: [{provide: CoreService, useClass: MatriculaService}]
})
export class MatriculaDetalhesComponent implements OnInit, OnDestroy {
  private matriculaPermissions: Permissoes;
  @ViewChild('detailsTab', { static: true}) public detailsTab: TabsetComponent;

  public agendaPermissions: Permissoes;
  public reposicaoPermissions: Permissoes;
  public mensalidadePermissions: Permissoes;

  @BlockUI() blockui: NgBlockUI;

  public dialogTitle = 'Detalhes da Matrícula';
  public photoAluno$: Observable<string>;

  public formGroup: UntypedFormGroup;
  public formGroupRenovation: UntypedFormGroup;
  private formControls: {
    id: UntypedFormControl;
    observacao?: UntypedFormControl;
    renovarAutomaticamente?: UntypedFormControl;
  };

  public item: Matricula = <Matricula>{};
  public partialRoute: string;
  public mensalidadeAberto = true;

  private actionItemSub: Subscription = new Subscription();
  private routerEventSubs: Subscription = new Subscription();

  constructor(
    protected service: MatriculaService,
    private clienteService: ClienteService,
    private userService: UserService,
    private authService: AuthService,
    public modal: BsModalRef,
    public modalService: BsModalService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.matriculaPermissions = this.authService.getPermissions('Matricula');
    this.agendaPermissions = this.authService.getPermissions('Agenda');
    this.reposicaoPermissions = this.authService.getPermissions('Reposicao');
    this.mensalidadePermissions = this.authService.getPermissions('MensalidadeAluno');
  }

  ngOnInit() {
    this.detailsTab.tabs[0].selectTab.emit();
    this.initFormControls();
    this.initFormControlsRenovation();
    this.loadItem();

    this.routerEventSubs = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modal.hide();
      }
    });

    this.actionItemSub = this.service.action.subscribe(action => {
      if (action && action == 'update') {
        this.loadItem();
      }
    });

  }

  ngOnDestroy() {
    this.service.setCurItem(null);
    this.service.setAction(null);
    this.actionItemSub.unsubscribe();
    this.routerEventSubs.unsubscribe();
  }

  initFormControls() {
    this.formControls = {
      id: new UntypedFormControl(null),
      observacao: new UntypedFormControl(null)
    };

    this.formGroup = new UntypedFormGroup(this.formControls);
  }

  private initFormControlsRenovation() {
    this.formControls = {
      id: new UntypedFormControl(null),
      renovarAutomaticamente: new UntypedFormControl(false)
    };

    this.formGroupRenovation = new UntypedFormGroup(this.formControls);
  }

  public selectTab(event: any, partialRoute: string) {
    this.partialRoute = partialRoute;
  }

  private loadItem(): void {
    this.blockui.start();
    const endpoint = this.item.id.toString();

    this.service.get(endpoint)
    .pipe(delay(1000), finalize(this.blockui.stop))
    .subscribe(
      entity => {
        this.item = entity;
        this.formGroup.patchValue(entity);
        this.formGroupRenovation.patchValue(entity);

        this.dialogTitle =
          entity.cancelado ? 'Detalhes da Matrícula [CANCELADA]' :
          entity.trancado ? 'Detalhes da Matrícula [TRANCADO]' :
          entity.finalizado ? 'Detalhes da Matrícula [FINALIZADA]' :
          'Detalhes da Matrícula';

        this.photoAluno$ = this.clienteService.getPhoto(this.item.aluno.id);

        if (this.item.cancelado || this.item.trancado || this.item.finalizado) {
          this.formGroupRenovation.get('renovarAutomaticamente').disable();
        }
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Error');
      }
    );
  }

  public saveObservacao() {
    if (!this.matriculaPermissions.atualizar) {
      this.toastr.warning('Você não permissão para Editar a matrícula.', 'Atenção');
      return;
    }

    this.blockui.start();

    const matricula: any = {
      matriculaId: this.formGroup.controls.id.value,
      observacao: this.formGroup.controls.observacao.value
    };

    this.service.put(matricula, 'observacao')
    .pipe(finalize(this.blockui.stop))
    .subscribe({
      next: (entity: Matricula) => {
        this.item = entity;
        this.formGroup.patchValue(entity);
        this.toastr.success('Observações atualizadas!', 'Sucesso');
      },
      error: erro => {
        console.log(erro);
        this.toastr.error(erro.message, 'Error');
      }
    });
  }

  public updateAutomaticRenovation() {
    if (!this.matriculaPermissions.atualizar) {
      this.toastr.warning('Você não tem permissão para Editar a matrícula.', 'Atenção');
      return;
    }

    if (this.item.plano.vigencia.numeroMeses == 0) {
      return;
    }

    if (this.item.finalizado || this.item.cancelado || this.item.trancado) {
      return;
    }

    this.blockui.start();

    const matricula: any = {
      matriculaId: this.formGroupRenovation.get('id').value,
      renovarAutomaticamente: this.formGroupRenovation.get('renovarAutomaticamente').value
    };

    this.service.put(matricula, 'renovarAutomaticamente')
    .pipe(finalize(this.blockui.stop))
    .subscribe({
      next: (entity: Matricula) => {
        this.item = entity;
        this.formGroup.patchValue(entity);
      },
      error: erro => {
        console.log(erro);
        this.toastr.error(erro.message, 'Error');
      }
    });
  }

  public recebeListaMensalidade(event: IMensalidade[]): void {
    const mensalidadesAberto: IMensalidade[] = event.filter(element => element.status !== 'Paga');

    this.mensalidadeAberto = mensalidadesAberto.length > 0;
  }

  public viewRenewButton() {
    if (this.item && !this.item.renovado && !this.mensalidadeAberto && this.item.dataFim &&
       (this.item.plano.vigencia && this.item.plano.vigencia.numeroMeses !== 0)) {
      return true;
    }

    return false;
  }

  public viewFinalizeButton(): boolean {
    if (this.item &&
      (this.item.plano.vigencia && this.item.plano.vigencia.numeroMeses != 0) &&
      !this.item.renovado && !this.item.finalizado &&
      !this.item.cancelado) {
      return true;
    }

    return false;
  }

  public viewPauseButton(): boolean {
    if (this.item &&
       (this.item.plano.vigencia && this.item.plano.vigencia.numeroMeses != 0) &&
      !this.item.cancelado && !this.item.trancado) {
        return true;
      }

      return false;
  }

  public viewRestartButton(): Boolean {
    if (this.item && this.item.trancado) {
      return true;
    }

    return false;
  }

  public renew() {
    if (!this.matriculaPermissions.inserir) {
      this.toastr.warning('Você não permissão para Renovar a matrícula.', 'Atenção');
      return;
    }

    this.item.modalidadeId = this.item.plano.modalidadeId;

    this.modalService.show(NovaMatriculaComponent, {
      class: 'modal-md',
      backdrop: false,
      ignoreBackdropClick: true,
      keyboard: false,
      animated: false,
      initialState: {
        item: this.item,
        clienteAtual: this.item.aluno
      }
    });

    this.modal.hide();
  }

  public openDialogDelete() {
    if (!this.matriculaPermissions.excluir) {
      this.toastr.warning('Você não permissão para Excluir a matrícula.', 'Atenção');
      return;
    }

    this.modalService.show(ModalWindowConfirmComponent, {
      class: 'modal-md modal-centered',
      initialState: {
        dialogTitle: 'Atenção',
        contentText: 'Ao excluir a matrícula do cliente,' +
        ' também serão excluídos todos os registros de mensalidades, agendamentos e reposições. Deseja continuar?'
      }
    });

    const deleteDialogOnHiddenSub = this.modalService.onHidden.subscribe(result => {
      if (result == 'true') {
        this.delete();
      }

      deleteDialogOnHiddenSub.unsubscribe();
    });
  }

  public openDialogStop() {
    if (!this.matriculaPermissions.atualizar) {
      this.toastr.warning('Você não tem permissão para Trancar a matrícula.', 'Atenção');
      return;
    }

    this.modalService.show(TrancaMatriculaComponent, {
      class: 'modal-app-secondary',
      initialState: {
        item: this.item
      }
    });
  }

  public openDialogRestart() {
    if (!this.matriculaPermissions.atualizar) {
      this.toastr.warning('Você não tem permissão para Destrancar a matrícula.', 'Atenção');
      return;
    }

    this.modalService.show(TrancaMatriculaComponent, {
      class: 'modal-app-secondary',
      initialState: {
        item: this.item,
        startAction: true,
        saveButtonLabel: 'Destrancar'
      }
    });

    console.log('restart');
  }

  private delete() {
    this.blockui.start();

    this.service.delete(this.item.id.toString())
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      () => {
        this.toastr.success('Matricula excluída com sucesso!');
        this.modal.hide();
      },
      error => {
        this.toastr.error(error.message, 'Erro');
      }
    );
  }

  public openDialogCancel() {
    if (!this.matriculaPermissions.atualizar) {
      this.toastr.warning('Você não permissão para Cancelar a matrícula.', 'Atenção');
      return;
    }

    this.modalService.show(CancelaMatriculaComponent, {
      class: 'modal-app-secondary',
      initialState: {
        item: this.item
      }
    });
  }

  public navigateToProfile() {
    if (!this.item.aluno) {
      return;
    }

    this.modal.hide();

    this.router.navigate(['cliente', this.item.aluno.id.toString()]);
  }

  public disableObservacao(): boolean {
    if (this.item && (this.item.cancelado || this.item.trancado)) {
      return true;
    }

    return false;
  }

}
