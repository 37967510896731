import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { Subscription, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { EditComponent, ModalWindowConfirmComponent, AlertMessageComponent } from '../../../../shared/components';

import { AgendaDetalhes } from '../../models';
import { ProfessionalService } from '../../../../professional/shared';
import { Professional } from '../../../../professional/shared/models';
import { LoggedUser } from '../../../../user/shared/models';

import { AgendaService } from '../../services';
import { UserService } from '../../../../user/shared';
import { MatriculaService } from '../../../../matriculas/shared';

@Component({
  selector: 'app-agenda-form-edit',
  templateUrl: './agenda-form-edit.component.html',
  styleUrls: ['./agenda-form-edit.component.scss']
})
export class AgendaFormEditComponent extends EditComponent<AgendaDetalhes> implements OnInit, OnDestroy {
  public static resourceName = 'CorrigirAgenda';

  public instructorList: Professional[] = [];
  public listHour$: Observable<{ hora: number, descricao: string }[]>;

  public deleteButtonTitle = 'Cancelar Agenda';
  public deleteButtonIcon = 'fa fa-ban';
  public deleteButtonLabel = 'Cancelar Agenda';

  private statusAgendaCorrigir: string[] = [
    'marcarPresenca',
    'marcarFalta',
    'justificarFalta',
    'cancelarJustificativa',
    'excluirReposicao'
  ];

  public loggedUser: LoggedUser = <LoggedUser>{};

  private loggedUserSub: Subscription = new Subscription();

  constructor(
    protected injector: Injector,
    protected service: AgendaService,
    private userService: UserService,
    private profissionalService: ProfessionalService,
    private matriculaService: MatriculaService
  ) {
    super(injector, service);

    this.saveButton = true;
    this.saveButtonLabel = 'Corrigir';
    this.showCloseButton = true;

    this.findInstructors();

    this.listHour$ = this.service.listHour;
  }

  ngOnInit() {
    if (this.item.statusAgendaId === 2) {
      this.deleteButton = true;
    }

    this.userService.loggedUser.subscribe(user => {
      this.loggedUser = user;
    });

    super.ngOnInit();
  }

  ngOnDestroy() {
    this.service.setCurItem(null);
    this.service.setAction(null);
    this.loggedUserSub.unsubscribe();
    super.ngOnDestroy();
  }

  public save() {
    if (!this.permissions.atualizar) {
      this.toastr.warning('Você não tem permissão para Corrigir agendamentos.', 'Atenção');

      return;
    }

    this.modalService.show(ModalWindowConfirmComponent, {
      class: 'modal-centered modal-sencodary',
      backdrop: 'static',
      initialState: {
        dialogTitle: 'Atenção',
        contentText: 'Tem certeza que deseja corrigir esta agenda?'
      }
    });

    const modalSub = this.modalService.onHidden.subscribe(action => {
      if (action && action == 'true') {
        this.checkStatus();
        this.submitted = true;

        if (this.formGroup.valid) {
          this.putAgenda();
        } else {
          this.toastr.info('Campos inválidos. Verifique as informações digitadas!');
        }
      } else {
        this.toastr.info('Ação cancelada pelo usuário.');
      }

      modalSub.unsubscribe();
    });
  }

  public delete() {
    if (!this.permissions.excluir) {
      this.toastr.warning ('Você não tem permissão para Cancelar agendamentos.');

      return;
    }

    this.modalService.show(ModalWindowConfirmComponent, {
      class: 'modal-centered modal-app-sencondary',
      initialState: {
        dialogTitle: 'Atenção',
        contentText: 'Tem certeza que deseja cancelar esta agenda?'
      }
    });

    const modalSub = this.modalService.onHidden.subscribe(action => {
      if (action && action == 'true') {
        this.cancelAgenda();
      }

      modalSub.unsubscribe();
    });
  }

  private putAgenda() {
    this.blockui.start();
    const entity = this.formGroup.getRawValue();

    this.service.corrigir(entity)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      () => {
        this.modal.hide();
        this.toastr.success('Agenda corrigida!');

        if (entity.excluirReposicao) {
          this.service.setAction('delete');
        }

        if (entity.cancelarJustificativa) {
          this.matriculaService.setAction('update');
        }
      },
      error => {
        this.toastr.error(error.message);
        this.resetStatus();
      }
    );
  }

  private cancelAgenda() {
    const entity = this.formGroup.getRawValue();

    this.blockui.start();

    this.service.cancelAgenda(entity)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      () => {
        this.modal.hide();
        this.toastr.success('Aula cancelada com sucesso!');
      },
      error => {
        this.toastr.error(error.message, 'Erro');
      }
    );
  }

  public initFormControls(): void {
    this.formControls = {
      id: new UntypedFormControl(),
      data: new UntypedFormControl(),
      hora: new UntypedFormControl(),
      funcionarioId: new UntypedFormControl(['', Validators.required]),
      status: new UntypedFormControl(),
      marcarPresenca: new UntypedFormControl(false),
      marcarFalta: new UntypedFormControl(false),
      justificarFalta: new UntypedFormControl(false),
      cancelarJustificativa: new UntypedFormControl(false),
      excluirReposicao: new UntypedFormControl(false),
      justificativa: new UntypedFormControl(''),
    };
  }

  private checkStatus(): void {
    const statusValue = this.formGroup.controls.status.value;

    this.statusAgendaCorrigir.forEach(el => {
      if (el == statusValue) {
        this.formGroup.controls[el].setValue(true);
      } else {
        this.formGroup.controls[el].setValue(false);
      }
    });

  }

  private resetStatus(): void {
    this.formGroup.controls.status.reset();
    this.formGroup.controls.marcarPresenca.setValue(false);
    this.formGroup.controls.marcarFalta.setValue(false);
    this.formGroup.controls.justificarFalta.setValue(false);
    this.formGroup.controls.cancelarJustificativa.setValue(false);
    this.formGroup.controls.excluirReposicao.setValue(false);
  }

  public getColorStatusAgenda(statusAgenda?: string): string {
    if (!statusAgenda) { return ''; }

    const status = [
      {sigla: 'EX', class: 'badge badge-info'},
      {sigla: 'HF', class: 'badge badge-light text-primary'},
      {sigla: 'PR', class: 'badge badge-success'},
      {sigla: 'JU', class: 'badge badge-secondary text-line-through'},
      {sigla: 'FA', class: 'badge badge-danger'},
      {sigla: 'RE', class: 'badge badge-warning'},
      {sigla: 'HL', class: 'badge badge-primary'}
    ];

    const objStatus = status.filter(element => element.sigla == statusAgenda)[0];
    return objStatus.class;
  }

  private findInstructors() {
    this.profissionalService.getList('agenda').subscribe(
      list => {
        this.instructorList = list;
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Error');
      }
    );
  }

  public checkFutureDate(): boolean {
    if (typeof this.item.data !== 'object') {
      return;
    }

    const curDate = new Date();
    const curDatetime = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate(), 0, 0).getTime();
    const scheduleDate = this.item.data;
    const scheduleDateTime = new Date(scheduleDate.getFullYear(), scheduleDate.getMonth(), scheduleDate.getDate(), 0, 0).getTime();

    return scheduleDateTime > curDatetime;
  }

}