export class AgendaStatus {
    id: number;
    sigla: string;
    descricao: string;
    manual: boolean;
    constructor() {
        this.id = 0;
        this.sigla = 'HF';
    }
}
