// tslint:disable:no-bitwise
export enum Permissao {
    visualizar = 1 << 0,
    inserir = 1 << 1,
    atualizar = 1 << 2,
    excluir = 1 << 3,
    todasPermissoes = visualizar | inserir | atualizar | excluir,
  }

  // tslint:enable:no-bitwise
  export interface Permissoes {
    visualizar: boolean;
    inserir: boolean;
    atualizar: boolean;
    excluir: boolean;
  }
