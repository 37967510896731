import { OnDestroy, OnInit, Injector, Directive } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

import { IEntity, CoreService } from '../core.service';
import { AuthService } from '../../auth/shared';

import { ListConfig } from '../models';
import { Permissoes } from '../../auth/shared/models';

@Directive()
export abstract class ListDirective<T extends IEntity> implements OnInit, OnDestroy {
    @BlockUI() blockui: NgBlockUI;
    modalService: BsModalService;
    toastr: ToastrService;
    protected pageTitleService: Title;
    protected authService: AuthService;
    public permissions: Permissoes;

    public listOfItem: T[] = [];
    public listPartialOfItems: T[] = [];
    protected curItem: T = <T>{};
    public formComponent: any;
    public formComponentClass = 'modal-lg';

    public currentPage = 1;
    public emptyList: boolean;

    public baseListConfig: ListConfig = <ListConfig> {
        title: 'Nova Janela',
        icon: 'fa fa-database',
        reloadButtonIsShown: true,
        addButtonIsShown: false,
        pagination: {
            enabled: true,
            maxSize: 8,
            itemsPerPage: 10
        }
    };

    protected curItemSubscription: Subscription = new Subscription();
    protected actionSubscription: Subscription = new Subscription();

    constructor(
        injector: Injector,
        protected service: CoreService<T>) {
            this.modalService = injector.get(BsModalService);
            this.toastr = injector.get(ToastrService);
            this.pageTitleService = injector.get(Title);
            this.authService = injector.get(AuthService);

        this.permissions = this.authService.getPermissions(this.constructor['resourceName']);
    }

    ngOnInit() {
        this.loadList();

        this.curItemSubscription = this.service.getCurItem().subscribe(entity => {
          if (entity) {
              this.curItem = entity;
          }
        });

        this.actionSubscription = this.service.action.subscribe(action => {
            if (action && action == 'delete') {
                this.service.removeItemFromArrayList(this.listOfItem, this.curItem, 'id');
                this.service.removeItemFromArrayList(this.listPartialOfItems, this.curItem, 'id');

                this.service.setCurItem(null);
            } else if (action && action == 'update') {
                this.service.updateArrayList(this.listOfItem, this.curItem);
                this.service.updateArrayList(this.listPartialOfItems, this.curItem);
            }
        });
    }

    ngOnDestroy() {
        this.curItemSubscription.unsubscribe();
        this.actionSubscription.unsubscribe();

        this.service.setAction(null);
        this.service.setCurItem(null);
    }

    public loadList(endpoint?: string) {
        this.emptyList = false;
        this.currentPage = 1;

        this.blockui.start();

        this.service.getList(endpoint)
            .pipe(finalize(this.blockui.stop))
            .subscribe(
                data => {
                    if (!data || data.length == 0) {
                        this.emptyList = true;
                        this.listOfItem = [];
                        return;
                    }

                    this.listOfItem = data;
                    this.listPartialOfItems = this.listOfItem.slice(0, this.baseListConfig.pagination.itemsPerPage);
                },
                error => {
                    console.log(error);
                    this.toastr.error(error.message, 'Error');
                });
    }

    public openItemDialog(entity = {id: 0} as IEntity) {
        if (entity.id && !this.permissions.atualizar) {
            this.toastr.warning('Você não tem permissão para Editar.', 'Atenção');
            return;
        }

        if (!entity.id && !this.permissions.inserir) {
            this.toastr.warning('Você não tem permissão para Inserir.', 'Atenção');
            return;
        }

        this.modalService.show(this.formComponent, {
          class: this.formComponentClass,
          backdrop: 'static',
          animated: false,
          initialState: {
            item: entity || <IEntity>{}
          }
        });
      }

    public pageChanged(event: PageChangedEvent): void {
        const startItem = (event.page - 1) * event.itemsPerPage;
        const endItem = event.page * event.itemsPerPage;
        this.listPartialOfItems = this.listOfItem.slice(startItem, endItem);
    }

    public printPage(): void {
        window.print();
    }

    public customDateAgenda(numero?: number): Date {
        if (!numero) {
            return;
        }

        if (typeof numero !== 'number') {
            return numero;
        }

        const numeroString = numero.toString();
        const ano = Number(numeroString.substr(0, 4));
        const mes = Number(numeroString.substr(4, 2));
        const dia = Number(numeroString.substr(6, 2));

        return new Date(ano, mes - 1, dia, 0, 0);
    }

}
