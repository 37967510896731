import { Component, OnInit, Injector } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { EditComponent } from '../../../../shared/components';

import { Matricula } from '../../models';

import { MatriculaService } from '../../services';

@Component({
  selector: 'app-cancela-matricula',
  templateUrl: './cancela-matricula.component.html',
  styleUrls: ['./cancela-matricula.component.scss']
})
export class CancelaMatriculaComponent extends EditComponent<Matricula> {
  static resourceName = 'Matricula';
  public saveButton = true;
  public saveButtonLabel = 'Cancelar';

  constructor(
    protected injector: Injector,
    service: MatriculaService
  ) {
    super(injector, service);
  }

  public save() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      this.toastr.info('Campos inválidos. Verifique as informações digitadas!');
      return;
    }

    this.blockui.start();

    const item = this.formGroup.getRawValue() as Matricula;
    const successMessage = this.item.id ? 'Registro alterado com sucesso.' : 'Registro criado com sucesso.';

    item.dataInicio = new Date(item.dataInicio).toDateString();

    this.service.put(item, 'cancelar')
    .pipe(finalize(this.blockui.stop))
    .subscribe(
        () => {
            this.toastr.success(successMessage);
            this.modal.hide();
        },
        error => {
            this.toastr.error(error.message);
        }
    );

  }

  public initFormControls () {
    this.formControls = {
      matriculaId: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      inicioCancelamento: new UntypedFormControl(new Date(), Validators.required),
      valorMulta: new UntypedFormControl(0),
      motivo: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      tipoPagamentoId: new UntypedFormControl(1)
    };
  }

  protected patchFormGroup() {
    this.item.matriculaId = this.item.id;

    super.patchFormGroup();
  }

}
