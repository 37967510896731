import { Component } from '@angular/core';

import { AuthService } from '../../views/auth/shared';
import { UserService } from '../../views/user/shared';

import { LoggedUser } from '../../views/user/shared/models';
import { Permissoes } from '../../views/auth/shared/models';

@Component({
  selector: 'app-simple-layout',
  template: '<block-ui><router-outlet></router-outlet></block-ui>'
})
export class SimpleLayoutComponent {
  public loggedUser: LoggedUser;
  public clientPermissions = {} as Permissoes;

  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {
    this.authService.findResources().then(() => {
      this.clientPermissions = this.authService.getPermissions('Aluno');
      this.getLoggedUser();
    });

  }

  private getLoggedUser(): void {
    this.userService.getLoggedUser()
    .subscribe(
      user => {
        this.loggedUser = user;
      }, error => {
        console.log(error);
      }
    );
  }

}
