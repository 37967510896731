import { Component, Injector, OnInit } from '@angular/core';

import { MensalidadeDetalhesBaseDirective } from './mensalidade-detalhes-base.directive';
import { MatriculaDetalhesComponent } from '../../../../matriculas/shared/components';

import { MensalidadeService } from '../../services';

import { Permissoes } from '../../../../auth/shared/models';
import { Plano } from '../../../../plano/shared/models';
import { Matricula } from '../../../../matriculas/shared/models';

@Component({
  selector: 'app-mensalidade-detalhes',
  templateUrl: './mensalidade-detalhes-layout.component.html',
  styleUrls: ['./mensalidade-detalhes-layout.component.scss']
})
export class MensalidadeDetalhesComponent extends MensalidadeDetalhesBaseDirective {
  public static resourceName = 'Mensalidade';
  private matriculaPermissions: Permissoes;
  public visualizarDetalheMatricula = true;

  constructor(
    protected injector: Injector,
    protected service: MensalidadeService
  ) {
    super(injector, service);

    this.matriculaPermissions = this.authService.getPermissions('Matricula');
  }

  public printInvoice(): void {
    super.printInvoice('Mensalidade');
  }

  public abrirDetalhesMatricula(): void {
    if (!this.matriculaPermissions.visualizar) {
      this.toastr.warning('Você não tem permissão para Visualizar a matrícula.', 'Atenção');
      return;
    }

    const matricula = { id: this.item.matriculaId, plano: {} as Plano } as Matricula;

    this.modal.hide();

    this.modalService.show(MatriculaDetalhesComponent, {
      animated: true,
      class: 'modal-lg',
      backdrop: 'static',
      initialState: {
        item: matricula
      }
    });
  }

}
