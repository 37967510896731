import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CoreService } from '../../../shared/core.service';

import { FechamentoComissao } from '../models';


@Injectable()
export class ComissaoService extends CoreService<FechamentoComissao> {
    protected url = this.api.url + 'fechamento_comissao/';

    constructor(
        protected injector: Injector
    ) {
        super(injector);
    }

    public postBulk(entity: FechamentoComissao): Observable<FechamentoComissao[]> {
        return this.http.post<FechamentoComissao[]>(this.url, entity, this.getHeaders())
        .pipe(
            map(data => data),
            catchError(this.handleError)
        );
    }

    public deleteCustom(endpoint: string): Observable<boolean> {
        return this.http.delete(this.url + endpoint, this.getHeaders())
        .pipe(
            map(data => true),
            catchError(this.handleError)
        );
    }
}
