import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localePtBr from '@angular/common/locales/pt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BlockUIModule } from 'ng-block-ui';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';

import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ngx-currency/src/currency-mask.config';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './views/auth/auth.module';

import { AppComponent } from './app.component';
import {
  DefaultLayoutComponent,
  SimpleLayoutComponent,
  AuthLayoutComponent,
  ClearLayoutComponent} from './layouts';

import { UserService } from './views/user/shared';
import { ProfessionalService } from './views/professional/shared';
import { ClienteService } from './views/cliente/shared';
import { AssinaturaService } from './views/home/shared';

import { AvatarModule } from 'ngx-avatar';
import { AvisosNoticiasService } from './views/avisos-noticias/shared/service/avisosNoticias.service';
import { PerfilModule } from './views/professional/perfil-component/perfil.module';

const APP_LAYOUTS = [
  DefaultLayoutComponent,
  SimpleLayoutComponent,
  AuthLayoutComponent,
  ClearLayoutComponent
];

const SERVICES = [
  UserService,
  ClienteService,
  ProfessionalService,
  AssinaturaService,
  AvisosNoticiasService
];

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: false
};

defineLocale('pt-br', ptBrLocale);
registerLocaleData(localePtBr, 'pt-br');

@NgModule({
  imports: [
    PerfilModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot({timeOut: 4000, preventDuplicates: true, progressBar: true}),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    BlockUIModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),
    GuidedTourModule,
    AuthModule,
    AvatarModule
  ],
  exports: [
    ToastrModule,
    TypeaheadModule,
    NgxCurrencyModule,
    NgxMaskModule
  ],
  declarations: [
    AppComponent,
    ...APP_LAYOUTS
  ],
  providers: [
    ...SERVICES,
    { provide: LOCALE_ID, useValue: 'pt-br' },
    { provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig },
    GuidedTourService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
