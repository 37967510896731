import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ComissaoService } from './comissao-service';

@Injectable()
export class RelatorioComissaoService extends ComissaoService {
  protected url = `${this.api.url}relatorioComissao/`;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public deleteCustom(endpoint: string): Observable<boolean> {
    return this.http.delete(`${this.api.url}fechamento_comissao/${endpoint}`, this.getHeaders())
    .pipe(
        map(data => true),
        catchError(this.handleError)
    );
  }

}
