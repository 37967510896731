import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../shared';

import { Registro } from '../shared/models';
import { ValidatePassword } from '../shared/password.validator';

@Component({
  selector: 'app-confirma-cadastro',
  templateUrl: './confirma-cadastro.component.html',
  styleUrls: ['./confirma-cadastro.component.scss']
})
export class ConfirmaCadastroComponent implements OnInit, OnDestroy {
  @BlockUI() blockui: NgBlockUI;
  public formGroup: UntypedFormGroup;
  private hashConfirmacao: string;
  private submitted: boolean;

  public newUser = 'False';

  private routerSubscription: Subscription = new Subscription();

  constructor(
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    protected injector: Injector,
    protected service: AuthService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Agenda Pilates - Confirmar cadastro');

    this.initFormControls();

    this.routerSubscription = this.route.queryParams.subscribe(params => {
      if ('hash' in params) {
        this.hashConfirmacao = params['hash'];
      } else {
        this.router.navigate(['/', 'auth']);
      }

      if ('novo-usuario' in params) {
        this.newUser = params['novo-usuario'];

        if (this.newUser == 'False') {
          this.formGroup.get('password').clearValidators();
          this.formGroup.get('passwordConfirm').clearValidators();
          this.formGroup.updateValueAndValidity();
        } else {
          this.formGroup.validator = ValidatePassword.MatchPassword;
        }
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  public initFormControls() {
    this.formGroup = new UntypedFormGroup({
      empresa: new UntypedFormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      passwordConfirm: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      termos: new UntypedFormControl(false)
    });
  }

  public onSubmitFormGroup(): void {
    if (!this.hashConfirmacao) {
      return;
    }

    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (!this.formGroup.get('termos').value) {
      this.toastr.warning('Você deve aceitar os temos para continuar.', 'Atenção');
      return;
    }

    this.blockui.start();

    const registro = {
      empresa: this.formGroup.get('empresa').value,
      senha: this.formGroup.get('password').value,
      confirmaSenha: this.formGroup.get('passwordConfirm').value,
    } as Registro;

    this.service.validarEmailAssinatura(registro, this.hashConfirmacao)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      bool => {
        if (!!bool) {
          this.toastr.success('Cadastro validado, seu acesso foi liberado!', 'Sucesso', { timeOut: 6000 });
          this.router.navigate(['/', 'auth', 'login']);
        } else {
          this.toastr.warning('Não foi possível validar seu cadastro. Enviamos um e-mail com mais informações.', 'Atenção');
        }
      },
      error => {
        this.toastr.error(error.message, 'Erro', { timeOut: 5000 });
      }
    );
  }

  validateField(field: string, nested?: boolean, parentControl?: string) {
    if (nested) {
        return this.formGroup['controls'][parentControl]['controls'][field].invalid &&
          (this.formGroup['controls'][parentControl]['controls'][field].touched || this.submitted);
    }

    return this.formGroup.controls[field].invalid && (this.formGroup.controls[field].touched || this.submitted);
}

}
