import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Home, Aniversariante, Widget } from './models';

import { CoreService } from '../../shared/core.service';
import { PlanoSimples } from '../../plano/shared/models';
import { AgendaResumo } from '../../agenda/shared/models';

@Injectable()
export class DashboardService extends CoreService<Home> {
protected url = this.api.url;

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  public buscarAniversariantes(): Observable<Aniversariante[]> {
    return this.http.get<Aniversariante[]>(`${this.url}home/aniversariantes`, this.getHeaders())
    .pipe(
      map(data => {
        data.map(el => {
          const today = new Date().getDate();
          el['hoje'] = Number(el.diaAniversario) == today ? true : false;
        });

        const tmpData: Aniversariante[] = [];
        const onlyToday = data.filter(el => el.hoje == true);
        const otherDays = data.filter(el => el.hoje == false);

        onlyToday.forEach(el => tmpData.push(el));
        otherDays.forEach(el => tmpData.push(el));

        return tmpData;
      }),
      catchError(this.handleError)
    );
  }

  public buscarPlanos(endpoint: string): Observable<PlanoSimples[]> {
    return this.http.get<PlanoSimples[]>(this.url + endpoint, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public getWidget(endpoint: string): Observable<Widget> {

    return this.http.get<number>(this.url + endpoint, this.getHeaders())
    .pipe(
      map(data => {
        return {value: data };
      }),
      catchError(this.handleError)
    );
  }

  public getFullWidget(endpoint: string): Observable<Widget> {

    return this.http.get<Widget>(this.url + endpoint, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public buscarResumoAgenda(): Observable<AgendaResumo> {
    const url = this.url + 'agenda/home/resumo';

    return this.http.get<AgendaResumo>(url, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

}
