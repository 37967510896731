import { Component, OnInit, AfterContentChecked, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { EventEmitter } from 'events';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { Modalidade } from '../../../../modalidades/shared/models';
import { Professional } from '../../../../professional/shared/models';
import {
  AgendaFuncionario,
  AgendaGeneric,
  AgendaStatus,
  AgendaDetalhesSimples} from '../../models';
import { Cliente } from '../../../../cliente/shared/models';
import { AgendaDetalhes } from '../../models';
import { Permissoes } from '../../../../auth/shared/models';

import { ProfessionalService } from '../../../../professional/shared';
import { ModalidadeService } from '../../../../modalidades/shared/modalidade.service';
import { AgendaService } from '../../services';
import { MatriculaService } from '../../../../matriculas/shared/services';
import { ReposicaoService } from '../../../../reposicao/shared';
import { ClienteService } from '../../../../cliente/shared';
import { DateAgendaCustomPipe } from '../../../../shared/pipes';
import { AuthService } from '../../../../auth/shared';

export const horasAgenda: {hora: number, descricao: string}[] = [
  {hora: 500, descricao: '05:00'},
  {hora: 515, descricao: '05:15'},
  {hora: 530, descricao: '05:30'},
  {hora: 545, descricao: '05:45'},
  {hora: 600, descricao: '06:00'},
  {hora: 615, descricao: '06:15'},
  {hora: 630, descricao: '06:30'},
  {hora: 645, descricao: '06:45'},
  {hora: 700, descricao: '07:00'},
  {hora: 715, descricao: '07:15'},
  {hora: 730, descricao: '07:30'},
  {hora: 745, descricao: '07:45'},
  {hora: 800, descricao: '08:00'},
  {hora: 815, descricao: '08:15'},
  {hora: 830, descricao: '08:30'},
  {hora: 845, descricao: '08:45'},
  {hora: 900, descricao: '09:00'},
  {hora: 915, descricao: '09:15'},
  {hora: 930, descricao: '09:30'},
  {hora: 945, descricao: '09:45'},
  {hora: 1000, descricao: '10:00'},
  {hora: 1015, descricao: '10:15'},
  {hora: 1030, descricao: '10:30'},
  {hora: 1045, descricao: '10:45'},
  {hora: 1100, descricao: '11:00'},
  {hora: 1115, descricao: '11:15'},
  {hora: 1130, descricao: '11:30'},
  {hora: 1145, descricao: '11:45'},
  {hora: 1200, descricao: '12:00'},
  {hora: 1215, descricao: '12:15'},
  {hora: 1230, descricao: '12:30'},
  {hora: 1245, descricao: '12:45'},
  {hora: 1300, descricao: '13:00'},
  {hora: 1315, descricao: '13:15'},
  {hora: 1330, descricao: '13:30'},
  {hora: 1345, descricao: '13:45'},
  {hora: 1400, descricao: '14:00'},
  {hora: 1415, descricao: '14:15'},
  {hora: 1430, descricao: '14:30'},
  {hora: 1445, descricao: '14:45'},
  {hora: 1500, descricao: '15:00'},
  {hora: 1515, descricao: '15:15'},
  {hora: 1530, descricao: '15:30'},
  {hora: 1545, descricao: '15:45'},
  {hora: 1600, descricao: '16:00'},
  {hora: 1615, descricao: '16:15'},
  {hora: 1630, descricao: '16:30'},
  {hora: 1645, descricao: '16:45'},
  {hora: 1700, descricao: '17:00'},
  {hora: 1715, descricao: '17:15'},
  {hora: 1730, descricao: '17:30'},
  {hora: 1745, descricao: '17:45'},
  {hora: 1800, descricao: '18:00'},
  {hora: 1815, descricao: '18:15'},
  {hora: 1830, descricao: '18:30'},
  {hora: 1845, descricao: '18:45'},
  {hora: 1900, descricao: '19:00'},
  {hora: 1915, descricao: '19:15'},
  {hora: 1930, descricao: '19:30'},
  {hora: 1945, descricao: '19:45'},
  {hora: 2000, descricao: '20:00'},
  {hora: 2015, descricao: '20:15'},
  {hora: 2030, descricao: '20:30'},
  {hora: 2045, descricao: '20:45'},
  {hora: 2100, descricao: '21:00'},
  {hora: 2115, descricao: '21:15'},
  {hora: 2130, descricao: '21:30'},
  {hora: 2145, descricao: '21:45'},
  {hora: 2200, descricao: '22:00'},
  {hora: 2215, descricao: '22:15'},
  {hora: 2230, descricao: '22:30'},
  {hora: 2245, descricao: '22:45'},
  {hora: 2300, descricao: '23:00'},
  {hora: 2315, descricao: '23:15'},
  {hora: 2330, descricao: '23:30'},
  {hora: 2345, descricao: '23:45'},
];

@Component({
  selector: 'app-agenda-form',
  templateUrl: './agenda-form.component.html',
  styleUrls: ['./agenda-form.component.scss']
})
export class AgendaFormComponent implements OnInit, AfterContentChecked, OnDestroy {
  @BlockUI() blockui: NgBlockUI;
  @Output() action = new EventEmitter();

  public permissoesReposicao: Permissoes;

  public dialogConfig: any = {
    title: 'Marcar Novo Agendamento',
    icon: 'fa fa-calendar'
  };

  private time;

  public typeaheadLoading: boolean;

  public listHorasAgenda = Object.assign(horasAgenda);

  public form: UntypedFormGroup;

  public clients: any[] = [{}];

  public listModalidades: Modalidade[] = [];
  private listAgendaStatus: AgendaStatus[] = [];
  public listProfissionaisAgenda: Professional[] = [];

  public listMatriculaLivre: any[] = [];
  public listMatriculaReposicoes: any[] = [];

  public agendaGeneric: AgendaGeneric = new AgendaGeneric();
  public currentAgenda: AgendaDetalhes = new AgendaDetalhes();

  public currentCliente: Cliente = <Cliente>{};
  public currentProfissional: Professional = <Professional>{};
  public currentModalidade: Modalidade = <Modalidade>{};

  private currentProfissionalSubscription: Subscription = new Subscription();
  private currentModalidadeSubscription: Subscription = new Subscription();
  private changeSearchClientSubscritpion: Subscription = new Subscription();
  private listModalidadesSubscription: Subscription = new Subscription();
  private listAgendaStatusSubscription: Subscription = new Subscription();

  constructor(
    private agendaService: AgendaService,
    private modalidadeService: ModalidadeService,
    private professionalService: ProfessionalService,
    private clienteService: ClienteService,
    private matriculaService: MatriculaService,
    private reposicaoService: ReposicaoService,
    private authService: AuthService,
    public modal: BsModalRef,
    private toastr: ToastrService,
    private _localeService: BsLocaleService,
    private dateCustomPipe: DateAgendaCustomPipe
  ) { }

  ngOnInit() {
    this._localeService.use('pt-br');

    this.initFormControls();

    if (this.currentAgenda.agendaId > 0) {
      this.form.controls.data.disable();
      this.form.controls.telefone.disable();

      this.form.controls.modalidadeId.disable();

      this.buscarPorId();
    }

    this.buscarProfissionaisAgenda();

    this.listModalidadesSubscription = this.modalidadeService.listModalidades
    .subscribe(modalidades => {
      this.listModalidades = (modalidades && modalidades.length > 0) ? modalidades : [];
    });

    this.listAgendaStatusSubscription = this.agendaService.listAgendaStatus
    .subscribe(listStatus => {
      this.listAgendaStatus = (listStatus && listStatus.length > 0) ? listStatus : [];
    });

    this.currentProfissionalSubscription = this.professionalService.getCurItem()
    .subscribe(profissional => {
      if (profissional && profissional.id > 0 && this.agendaGeneric.agendaFuncionarios.length > 0) {
        this.currentProfissional = profissional;
      }
    });

    this.currentModalidadeSubscription = this.modalidadeService.currentModalidade
    .subscribe(modalidade => {
      if (modalidade && modalidade.id > 0) {
        this.currentModalidade = modalidade;
        this.currentAgenda.modalidade = modalidade;

        this.form.controls.modalidadeId.setValue(modalidade.id);
      }
    });

    this.pathFormGroup();

    this.permissoesReposicao = this.authService.getPermissions('Reposicao');
  }

  ngAfterContentChecked() {}

  ngOnDestroy() {
    this.currentProfissionalSubscription.unsubscribe();
    this.currentModalidadeSubscription.unsubscribe();
    this.changeSearchClientSubscritpion.unsubscribe();
    this.listModalidadesSubscription.unsubscribe();
    this.listAgendaStatusSubscription.unsubscribe();

    this.professionalService.setCurItem(null);
    this.agendaService.setCurAgenda(null);
    this.agendaService.setAction(null);
  }

  private initFormControls() {
    this.form = new UntypedFormBuilder().group({
      alunoId: [null],
      cliente: [null, Validators.required],
      clienteSimples: '',
      telefone: new UntypedFormBuilder().group({
        numero: ['', Validators.required]
      }),
      modalidadeId: [0, [Validators.required, Validators.min(1)]],
      funcionarioId: [0, [Validators.required, Validators.min(1)]],
      data: [null, Validators.required],
      hora: [5, Validators.required]
    });
  }

  public save() {
    const formValues = this.form.getRawValue();

    this.currentAgenda.funcionarioId = Number(this.form.getRawValue().funcionarioId);
    this.currentAgenda.alunoId = formValues.alunoId;

    const agenda: AgendaDetalhesSimples = new AgendaDetalhesSimples();
    agenda.alunoId = this.currentAgenda.alunoId;
    agenda.data = formValues.data.toDateString();
    agenda.hora = formValues.hora;
    agenda.funcionarioId = Number(formValues.funcionarioId);
    agenda.matriculaId = this.currentAgenda.matriculaId;
    agenda.modalidadeId = formValues.modalidadeId;
    agenda.reposicaoId = this.currentAgenda.eReposicao ? this.currentAgenda.reposicaoId : 0;

    let type = 'livre';
    if (this.currentAgenda.eReposicao) {
      this.postReposicao(agenda);
      return;
    } else if (this.currentAgenda.statusAgenda.sigla == 'EX' && !this.currentAgenda.eReposicao) {
      type = 'experimental';
    }

    if (this.currentAgenda.id > 0) {
      this.putAgenda();
    } else if (this.currentAgenda.id == 0 && this.currentAgenda.statusAgenda.sigla != 'EX') {
      this.postAgenda(type, agenda);
    } else {

      this.inserirAlunoSimples(aluno => {
        this.currentAgenda.aluno = aluno;
        this.currentAgenda.alunoId = aluno.id;
        agenda.alunoId = aluno.id;

        this.postAgenda(type, agenda);
      });
    }
  }

  private postAgenda(endpoint: string, agenda: AgendaDetalhesSimples) {
    this.blockui.start();

    this.agendaService.insert(endpoint, agenda)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      data => {
        this.agendaService.setCurAgenda(data);
        this.agendaService.setAction('insert');

        this.modal.hide();
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Error');
      }
    );
  }

  private postReposicao(agenda: AgendaDetalhesSimples) {
    this.blockui.start();

    this.reposicaoService.insert(agenda)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      data => {
        this.agendaService.setCurAgenda(data);
        this.agendaService.setAction('insert');
        this.modal.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error');
      }
    );
  }

  private putAgenda() {
    this.blockui.start();

    this.agendaService.update(this.currentAgenda)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      agenda => {
        this.removeAgenda(agenda, (str) => {
          if (str && str == 'ok') {
            this.agendaService.setCurAgenda(agenda);
            this.agendaService.setAction('update');
          }
        });

        this.modal.hide();
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Error');
      }
    );
  }

  private buscarPorId() {
    this.blockui.start();

    this.agendaService.getById(this.currentAgenda.agendaId)
    .pipe(finalize(this.blockui.stop)).subscribe(
      data => {
        this.currentAgenda = data;
        this.currentCliente = data.aluno;

        this.clients = [];
        this.clients.push({alunoId: data.aluno.id, output: data.aluno.nome});

        this.form.controls.alunoId.setValue(data.aluno.id);
        this.form.controls.alunoId.disable();

        this.form.controls.telefone.patchValue(data.aluno.telefone);
      },
      error => {
        console.log(error);
        this.toastr.error('Não foi possível buscar os dados do agendamento!');
        this.modal.hide();
      }
    );
  }

  private buscarProfissionaisAgenda() {
    this.professionalService.getList('agenda').subscribe(
      data => {
        this.listProfissionaisAgenda = data;

        if (this.currentProfissional && this.currentProfissional.id > 0) {
          this.currentAgenda.funcionario = this.listProfissionaisAgenda.filter(element =>
             element.id == this.currentProfissional.id)[0];
        }
      }, error => {
        console.log(error);
      }
    );
  }

  public onClearTypeahed($event: any): void {
    this.clients = [{}];
  }

  public onSearchClient($event: any) {
    if ($event.term.toString().length < 3) {
      return;
    }

    this.typeaheadLoading = false;

    clearTimeout(this.time);

    this.time = setTimeout(() => {
      this.buscarMatriculaCliente($event.term);
    }, 500);
  }

  private buscarMatriculaCliente(nomeAluno: string) {
    this.typeaheadLoading = true;

    if (this.currentAgenda.eReposicao) {
      this.reposicaoService.searchByClient(nomeAluno, this.currentModalidade.id).subscribe(
        data => {
          this.typeaheadLoading = false;

          this.makeListMatriculaReposicao(data);
        },
        error => {
          console.log(error);
          this.typeaheadLoading = false;
        }
      );
    } else {
      this.matriculaService.searchFreeSchedule(nomeAluno, this.currentModalidade.id).subscribe(
        data => {
          this.typeaheadLoading = false;

          if (data && data.length) {
            this.makeListMatriculaLivre(data);
          }
        },
        error => {
          console.log(error);
          this.typeaheadLoading = false;
        }
      );
    }

  }

  private inserirAlunoSimples(fn) {
    this.blockui.start();

    const form = this.form.getRawValue();
    const aluno = <Cliente> {};
    aluno.nome = form.clienteSimples;
    aluno.telefone = form.telefone;

    this.clienteService.postAlunoSimplificado(aluno)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      data => {
        fn(data);
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Error');
      }
    );
  }

  public changeStatusAgenda(sigla: string, reposicao: boolean) {
    if (!sigla) {
      return false;
    }

    if (reposicao) {
      if (!this.permissoesReposicao.inserir) {
        this.toastr.warning('Você não permissão para Inserir uma reposição.', 'Atenção');
        return;
      }
      this.typeaheadLoading = true;

      this.reposicaoService.searchByModality(this.currentModalidade.id).subscribe(
        data => {
          this.typeaheadLoading = false;

          this.makeListMatriculaReposicao(data);
        },
        error => {
          console.log(error);
          this.typeaheadLoading = false;
        }
      );
    }

    this.clients = [{}];
    this.form.controls.alunoId.setValue(null);

    if (this.currentAgenda.agendaId == 0) {
      this.form.controls.cliente.setValue(null);
      this.form.controls.telefone.patchValue({telefone: null});
    }

    this.currentAgenda.eReposicao = reposicao;

    const status = this.listAgendaStatus.filter(element => element.sigla == sigla)[0];
    this.currentAgenda.statusAgenda = status;
  }

  public selecTypeaheadtAluno($event: any) {
    if (!$event) {
      return;
    }

    this.currentAgenda.alunoId = $event.alunoId;
    this.currentAgenda.matriculaId = $event.matriculaId;
    this.currentAgenda.reposicaoId = $event.reposicaoId;
  }

  public onChangeProfessional($event) {
    this.currentAgenda.funcionario = $event;
  }

  public changeHora() {
    const hora = this.form.controls.hora.value;
    this.currentAgenda.hora = Number(hora);
  }

  private makeListMatriculaLivre(obj: any[]) {
    this.clients = [{}];

    obj.forEach((element) => {
      const matricula = {
        alunoId: element.alunoId,
        matriculaId: element.id,
        reposicaoId: 0,
        nome: element.aluno.nome,
        nomeCurto: element.aluno.nomeCurto,
        data: element.dataInicio,
        plano: element.plano.nome,
        output: element.aluno.nome + ' (' + element.aluno.nomeCurto + ')' + ' - ' + element.plano.nome +
         ' - Início: ' + this.dateCustomPipe.transform(element.dataInicio)
      };

      this.clients.push(matricula);
    });
  }

  private makeListMatriculaReposicao(obj: any[]) {
    this.clients = [{}];

    obj.forEach((element) => {
      const reposicao = {
        alunoId: element.alunoId,
        matriculaId: element.matriculaId,
        reposicaoId: element.id,
        nome: element.nome,
        nomeCurto: element.nomeCurto,
        data: element.data,
        plano: element.nomePlano,
        output: element.nome + ' (' + element.nomeCurto + ')' +
        ' - ' + this.dateCustomPipe.transform(element.data) + ' - ' +
        element.nomePlano
      };

     this.clients.push(reposicao);
    });
  }

  private removeAgenda(agenda: AgendaDetalhes, fn): void {
    const agendaFuncionario: AgendaFuncionario = this.agendaGeneric.agendaFuncionarios.filter(
      element => element.idFuncionario == this.currentProfissional.id)[0];

    const agendaDetalhes: AgendaDetalhes = agendaFuncionario.agendaDetalhes.filter(
      element => element.agendaId == agenda.id)[0];

    const index = agendaFuncionario.agendaDetalhes.indexOf(agendaDetalhes);

    if (index >= 0) {
      agendaFuncionario.agendaDetalhes.splice(index, 1);
    }

    if (agendaFuncionario.agendaDetalhes.length == 0) {
      const agendaFuncionarioFiltered = this.agendaGeneric.agendaFuncionarios.filter(element =>
          element.idFuncionario == agendaFuncionario.idFuncionario)[0];

      const indexAF = this.agendaGeneric.agendaFuncionarios.indexOf(agendaFuncionarioFiltered);
      this.agendaGeneric.agendaFuncionarios.splice(indexAF, 1);
    }

    return fn('ok');
  }

  private customDate(date: string | number) {
    if (typeof date == 'string') {
      return new Date(date);
    }

    const numeroString = date.toString();
    const ano = Number(numeroString.substr(0, 4));
    const mes = Number(numeroString.substr(4, 2));
    const dia = Number(numeroString.substr(6, 2));

    return new Date(ano, mes - 1, dia, 0, 0);
  }

  private pathFormGroup() {
    this.currentAgenda.data = this.customDate(this.agendaGeneric.data);
    this.currentAgenda.hora = this.agendaGeneric.hora;
    this.form.patchValue(this.currentAgenda);
  }
}
