import { Injector, OnDestroy, OnInit, Directive } from '@angular/core';
import { UntypedFormControl, Validators, AbstractControl, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { EditDirective, ModalWindowConfirmComponent } from '../../../../shared/components';

import { IMensalidade } from '../../models';
import { FormaPagamento } from '../../../../configuracoes/pagamento/shared/models';

import { CoreService } from '../../../../shared/core.service';

@Directive()
export class MensalidadeFormBaseDirective extends EditDirective<IMensalidade> implements OnInit, OnDestroy {

  public dialogTitle = 'Baixar Mensalidade';

   public dataPagamentoMax: Date;
   public permitirBaixarTodas: boolean;
   public visualizarDetalheMatricula: boolean;
   public viewSaveButton: boolean;
   public listaFormaPagamento = [] as FormaPagamento[];

  constructor(
    protected injector: Injector,
    protected service: CoreService<IMensalidade>,
  ) {
    super(injector, service);

    this.dataPagamentoMax = new Date();
  }

  ngOnInit() {
    this.viewSaveButton = this.item.status !== 'Paga';

    if (this.item.permiteBaixarTodas && this.item.status !== 'Parcial') {
      this.permitirBaixarTodas = true;
    }

    super.ngOnInit();

    this.formGroup.controls.dataVencimento.disable();
    this.formGroup.controls.valorMensalidade.disable();

    if (this.item && this.item.status.match(/paga|parcial/i)) {
      this.dialogTitle = 'Exibir Mensalidade';
      this.permitirBaixarTodas = false;
    }
  }

  ngOnDestroy() {
    this.service.setCurItem(undefined);
    super.ngOnDestroy();
  }

  initFormControls() {
    this.formControls = {
      id: new UntypedFormControl(0),
      dataPagamento: new UntypedFormControl(null, [Validators.required]),
      dataVencimento: new UntypedFormControl(null),
      valorMensalidade: new UntypedFormControl(null),
      valor: new UntypedFormControl(0, [Validators.min(0.00)]),
      desconto: new UntypedFormControl(0),
      juros: new UntypedFormControl(0),
      multa: new UntypedFormControl(0),
      tipoPagamentoId: new UntypedFormControl(0),
      baixarTodas: new UntypedFormControl(false),
      matriculaId: new UntypedFormControl(0),
    };
  }

  changeDiscount(event): void {

    if (event.keyCode === 9) {
      return
    }

    const valorMensalidade = this.item.valorEmAberto;
    const desconto = this.formGroup.get('desconto').value;
    let valor = 0;

    if (desconto < valorMensalidade) {
      valor = valorMensalidade - desconto;
    }
    this.formGroup.get('valor').setValue(parseFloat(valor.toFixed(2)));
  }

  public loadItem() {
    this.blockui.start();

    this.service.get(`baixar/${this.item.id.toString()}`)
      .pipe(finalize(this.blockui.stop))
      .subscribe(
        entity => {
          this.service.setCurItem(entity);
        },
        error => {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      );
  }

  public onSave(): void {
    const
      valorPagamento = this.formGroup.get('valor').value,
      desconto = this.formGroup.get('desconto').value;

    this.submitted = true;

    if (this.formGroup.invalid) {
      this.toastr.info('Campos inválidos. Verifique as informações digitadas!');
      return;
    }

    if (!this.permissions.atualizar) {
      this.toastr.warning('Você não tem permissão para Baixar mensalidade.', 'Atenção');
      return;
    }

    if ((valorPagamento + desconto) > this.item.valorEmAberto) {
      this.toastr.info('Valor a ser pago é maior que o valor em aberto.');
      return;
    }

    if ((valorPagamento + desconto) < this.item.valorEmAberto) {
      this.formGroup.get('baixarTodas').setValue(false);

      this.modalService.show(ModalWindowConfirmComponent, {
        class: 'modal-md shadow',
        initialState: {
          contentText: 'Valor a ser pago está menor que valor em aberto.',
          contentQuestion: 'Deseja realizar pagamento parcial?'
        }
      });

      const onHiddenModalSub: Subscription = this.modalService.onHidden.subscribe(reason => {
        if (reason == 'true') {
          this.save();
        }

        onHiddenModalSub.unsubscribe();
      });

      return;
    }

    this.save();
  }

  public save() {
    this.blockui.start();

    const item = this.formGroup.getRawValue() as IMensalidade;
    const message = this.item.id ? 'Registro alterado com sucesso.' : 'Registro criado com sucesso.';

    item.dataPagamento = new Date(item.dataPagamento).toDateString();
    item.dataVencimento = new Date(item.dataVencimento).toDateString();

    this.service.save(item)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      () => {
        this.toastr.success(message);
        this.modal.hide();
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

  public changeTipoPagamento(): void {
    this.formGroup.get('baixarTodas').setValue(false);
  }

  public getDiffValue() {
    const fGValues = this.formGroup.getRawValue(),
      diff = (fGValues.dinheiro + fGValues.cheque + fGValues.credito + fGValues.debito) - (fGValues.valor - fGValues.desconto);
    return diff;
  }

  public checkPaid(): boolean {
    if (this.item.status.match('/Paga|Parcial/i')) {
      return true;
    }
  }

  protected printInvoice(resourceName: string) {
    let endpoint = '';
    const baseHref = document.getElementsByTagName('base')[0].getAttribute('href');
    const componentList = {
        Mensalidade: `${baseHref}print/reports/recibo/mensalidade/${this.item.id}`,
        MensalidadeAluno: `${baseHref}print/reports/recibo/mensalidade-aluno/${this.item.id}`
    };

    if (resourceName in componentList) {
      endpoint = componentList[resourceName];
    } else {
      return;
    }

    const customWindow = window.open(endpoint,
      '_blank', 'top=0,left=0,location=no,status=0,width=' + window.innerWidth + ',height=' + window.innerHeight);
  }

  protected patchFormGroup() {
    const item = Object.assign({}, this.item) as IMensalidade;
    item.dataVencimento = new Date(item.dataVencimento.toString().slice(0, -1));

    this.formGroup.patchValue(item);
    this.formGroup.get('valor').setValue(item.valorEmAberto);

    if (this.item.status !== 'Paga') {
      this.formGroup.get('dataPagamento').setValue(new Date());
    }
  }

  public reverse() {
    if (!this.permissions.excluir) {
      this.toastr.warning('Você não tem permissão para Estornar mensalidade.', 'Atenção');
      return;
    }

    this.modalService.show(ModalWindowConfirmComponent, {
      class: 'modal-md',
      initialState: {
        contentText: 'Tem certeza que deseja Estornar esta mensalidade?'
      }
    });

    const onHiddenModalSub: Subscription = this.modalService.onHidden.subscribe(reason => {
      if (reason == 'true') {
        this.blockui.start();

        const mensalidade: any = {
          id: this.formGroup.controls.id.value,
        };

        this.service.put(mensalidade, 'estornar')
          .pipe(finalize(this.blockui.stop))
          .subscribe(
            () => {
              this.toastr.success('Mensalidade estornada com sucesso');
              this.modal.hide();
            },
            error => {
              this.toastr.error(error.message);
            }
          );
      }

      onHiddenModalSub.unsubscribe();
    });
  }
}
