import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { IMensalidade } from '../models';
import { FormaPagamento } from '../../../configuracoes/pagamento/shared/models';
import { MensalidadeService } from './mensalidade.service';

@Injectable()
export class MensalidadeAlunoService extends MensalidadeService {
    protected url = `${this.api.url}mensalidadeAluno/` as string;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

}
