import { Component, Injector } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ListComponent } from '../../../../shared/components';
import { ContaPagarFormComponent } from '../conta-pagar-form';

import { ContaPagar } from '../../models';

import { ContaPagarService } from '../../services';

@Component({
  selector: 'app-dialog-lista-conta-pagar',
  templateUrl: './dialog-lista-conta-pagar.component.html',
  styleUrls: ['./dialog-lista-conta-pagar.component.scss']
})
export class DialogListaContaPagarComponent extends ListComponent<ContaPagar> {
  public static resourceName = 'ContaPagar';
  public formComponent = ContaPagarFormComponent;
  public formComponentClass = 'modal-md';

  constructor(
    protected injector: Injector,
    protected service: ContaPagarService,
    public modal: BsModalRef
  ) {
    super(injector, service);
  }

  public loadList() {}

  public checkVencimento(date: number): boolean {
    const today = (new Date()).toDateString(),
          overdueDate = typeof date == 'number' ? new Date(
            Number(date.toString().substr(0, 4)),
            Number(date.toString().substr(4, 2)) - 1,
            Number(date.toString().substr(6, 2))).toDateString() : new Date(date).toDateString();

    if (!date) {
      return false;
    } else if (new Date(today).getTime() > new Date(overdueDate).getTime()) {
      return true;
    }
    return false;
  }

}
