import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CoreService } from '../../shared/core.service';
import { Plano, Vigencia } from './models';

@Injectable()
export class PlanoService extends CoreService<Plano> {
    protected url = this.api.url + 'planos/';

    constructor(
        protected injector: Injector
    ) {
        super(injector);
    }

    public getListVigencia(): Observable<Vigencia[]> {
        return this.http.get<Vigencia[]>(this.url + 'vigencia/', this.getHeaders())
        .pipe(
            map(data => data),
            catchError(this.handleError)
        );
  }

}
