import { NgModule } from '@angular/core';

import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';

import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { EmpresaModule } from '../empresa/empresa.module';

import { AuthService } from './shared';
import { AuthGuard } from './guards';

import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RegisterComponent } from './register/register.component';
import { SelecionaEmpresaComponent } from './seleciona-empresa';
import { ConfirmaCadastroComponent } from './confirma-cadastro';
import { TermosAdesaoComponent } from './termos-adesao';
import { RecuperarSenhaComponent } from './recuperar-senha';
import { RecoverCodeComponent } from './recover-code';
import { PasswordResetComponent } from './password-reset';

const COMPONENTS = [
  LoginComponent,
  LogoutComponent,
  RegisterComponent,
  SelecionaEmpresaComponent,
  ConfirmaCadastroComponent,
  TermosAdesaoComponent,
  RecuperarSenhaComponent,
  RecoverCodeComponent,
  PasswordResetComponent,
];

@NgModule({
  imports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule,
    AuthRoutingModule,
    EmpresaModule
  ],
  declarations: [
    ...COMPONENTS
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LfqYeUUAAAAAJxebXCHXYieyOz0SyMTxG851X64'} as RecaptchaSettings
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'pt-BR'
    },
    AuthService,
    AuthGuard
  ]
})
export class AuthModule { }
