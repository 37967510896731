import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RegisterComponent } from './register/register.component';
import { SelecionaEmpresaComponent } from './seleciona-empresa';
import { ConfirmaCadastroComponent } from './confirma-cadastro';
import { TermosAdesaoComponent } from './termos-adesao';
import { RecuperarSenhaComponent } from './recuperar-senha';
import { RecoverCodeComponent } from './recover-code';
import { PasswordResetComponent } from './password-reset';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Auth Module'
    },
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'logout',
        component: LogoutComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'seleciona-empresa',
        component: SelecionaEmpresaComponent
      },
      {
        path: 'confirma-cadastro',
        component: ConfirmaCadastroComponent
      },
      {
        path: 'termos-adesao',
        component: TermosAdesaoComponent
      },
      {
        path: 'recuperar-senha',
        component: RecuperarSenhaComponent
      },
      {
        path: 'recover-code',
        component: RecoverCodeComponent
      },
      {
        path: 'password-reset',
        component: PasswordResetComponent
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
