import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { UntypedFormControl, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { ContaPagar } from '../../models';

import { ContaPagarService } from '../../services';

import { EditComponent } from '../../../../shared/components';

@Component({
  selector: 'app-conta-pagar-form',
  templateUrl: './conta-pagar-form.component.html',
  styleUrls: ['./conta-pagar-form.component.scss'],
  providers: [{provide: CurrencyPipe, useClass: CurrencyPipe}]
})
export class ContaPagarFormComponent extends EditComponent<ContaPagar> implements OnInit, OnDestroy {
  public static resourceName = 'ContaPagar';
  public dialogTitle: string;
  public dialogIcon: string;
  public saveButton = true;
  public deleteButton = true;
  public printButton = false;
  public printButtonLabel = 'Recibo';
  public printButtonTitle = 'Imprimir Recibo';

  public showRecurrence: boolean;

  public recurrenceList = [
    {
      name: 'diariamente',
      label: 'Diariamente'
    },
    {
      name: 'semanalmente',
      label: 'Semanalmente'
    },
    {
      name: 'mensalmente',
      label: 'Mensalmente'
    },
    {
      name: 'anualmente',
      label: 'Anualmente'
    }
  ];

  constructor(
    protected injector: Injector,
    protected service: ContaPagarService,
    private currencyPipe: CurrencyPipe
  ) {
    super(injector, service);
  }

  ngOnInit() {
    const hasItem = (this.item && this.item.id > 0);
    this.dialogTitle = hasItem ? 'Alterar/Baixar Conta a Pagar' : 'Adicionar Conta a Pagar';
    this.dialogIcon = hasItem ? 'fa fa-edit' : 'fa fa-money';
    this.saveButtonLabel = hasItem ? 'Alterar' : 'Salvar';
    this.tituloConfirmacaoExclusao  = 'Excluir conta a pagar';
    this.mensagemConfirmacaoExclusao = 'Tem certeza que deseja excluir a conta: ' + this.item.descricao + '?';

    super.ngOnInit();

    if (this.item.id > 0 && this.item.dataPagamento) {
      this.printButton = true;
    }
  }

  ngOnDestroy() {
    this.service.setCurItem(null);

    super.ngOnDestroy();
  }

  public initFormControls() {
    this.formControls = {
      id: new UntypedFormControl(0),
      descricao: new UntypedFormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(250)]),
      dataVencimento: new UntypedFormControl(new Date(), [Validators.required]),
      valor: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      dataPagamento: new UntypedFormControl(new Date()),
      desconto: new UntypedFormControl(0),
      juros: new UntypedFormControl(0),
      multa: new UntypedFormControl(0),
      processarPagamento: new UntypedFormControl(false),
      repetir: new UntypedFormControl(false),
      frequencia: new UntypedFormControl('mensalmente'),
      ocorrencias: new UntypedFormControl(2),
      exibirNumeroParcela: new UntypedFormControl(false)
    };
  }

  public save() {
    this.submitted = true;

    const processarPagamento = this.formGroup.controls.processarPagamento.value,
          dataPagamento = this.formGroup.controls.dataPagamento.value,
          paymentValues = {
            desconto: this.formGroup.controls.desconto.value,
            juros: this.formGroup.controls.juros.value,
            multa: this.formGroup.controls.multa.value
          };


    if (!processarPagamento) { // create or update
      super.save();
    } else if (!this.item.id && processarPagamento) { // create and process payment
      this.createAccountToProcessPayment(conta => {
        this.item = conta;
        this.item.dataPagamento = dataPagamento;
        this.item.desconto = paymentValues.desconto;
        this.item.juros = paymentValues.juros;
        this.item.multa = paymentValues.multa;
        this.patchFormGroup();
        this.formGroup.disable();

        this.toastr.success('A conta criada!', 'Sucesso');

        this.processAccountPayment();
      });

    } else { // only process payment
      this.processAccountPayment();
    }
  }

  public createAccountToProcessPayment(fn) {
    this.blockui.start();

    this.service.post(this.formGroup.getRawValue())
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      account => fn(account),
      error => {
        this.toastr.error(error.message, 'Erro');
      }
    );
  }

  private processAccountPayment() {
    this.blockui.start();

    const entityValues: ContaPagar = this.formGroup.getRawValue();
    entityValues['dataPagamento'] = new Date(entityValues['dataPagamento']).toDateString();

    this.service.put(entityValues, 'baixar')
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      () => {
        this.formGroup.reset();
        this.toastr.success('Conta baixada com sucesso!', 'Sucesso');
        this.modal.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error');
        if (!this.item.fechamentoComissaoId) {
          this.formGroup.enable();
        }
      }
    );
  }

  public getPaid(): string {
    const value = this.formGroup.controls.valor.value + this.formGroup.controls.juros.value +
      this.formGroup.controls.multa.value - this.formGroup.controls.desconto.value;

    return this.currencyPipe.transform(value, 'BRL');
  }

  public onCheckProcessarPagamento(): void {
    if (this.formGroup.controls.processarPagamento.value) {
      this.formGroup.controls.dataPagamento.setValue(new Date());
      this.saveButtonLabel = this.item && this.item.id > 0 ? 'Baixar' : 'Salvar';
      this.saveButton = true;

      if (this.item.id) {
        this.formGroup.controls.descricao.disable();
        this.formGroup.controls.dataVencimento.disable();
      }
    } else {
      this.saveButtonLabel = this.item && this.item.id > 0 ? 'Alterar' : 'Salvar';
      this.formGroup.enable();
    }
    return;
  }

  protected patchFormGroup() {
    if (this.item.dataPagamento) {
      this.saveButton = false;
      this.formGroup.controls.processarPagamento.setValue(true);

      this.item.dataPagamento = (this.item.dataPagamento && typeof this.item.dataPagamento == 'number') ?
       this.customDateAgenda(Number(this.item.dataPagamento)) : this.item.dataPagamento;

      this.formGroup.disable();
    }

    this.item.dataVencimento = this.customDateAgenda(Number(this.item.dataVencimento));

    super.patchFormGroup();

    if (this.item.fechamentoComissaoId) {
      this.deleteButton = false;
      this.saveButtonLabel = 'Baixar';
      this.formGroup.controls.processarPagamento.setValue(true);
      this.formGroup.controls.processarPagamento.disable();
      this.formGroup.controls.valor.disable();
      this.formGroup.controls.descricao.disable();
      this.formGroup.controls.dataVencimento.disable();
    }

    if (this.item.mensalidadePagamentoId) {
      this.deleteButton = false;
      this.printButton = false;
      this.saveButtonLabel = 'Baixar';
      this.formGroup.controls.processarPagamento.setValue(true);
      this.formGroup.controls.processarPagamento.disable();
      this.formGroup.controls.valor.disable();
      this.formGroup.controls.descricao.disable();
      this.formGroup.controls.dataVencimento.disable();
    }

    if (!this.item.dataPagamento && this.item.fechamentoComissaoId) {
      this.formGroup.controls.dataPagamento.setValue(new Date());
    }

  }

  public printInvoice() {
    const baseHref = document.getElementsByTagName('base')[0].getAttribute('href');

    const customWindow = window.open(`${baseHref}print/reports/recibo/conta-pagar/${this.item.id}` ,
            '_blank', 'top=0,left=0,location=no,status=0,width=' + window.innerWidth + ',height=' + window.innerHeight);
  }

}
