interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  href?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Painel Geral',
    url: '/inicio',
    icon: 'icon-speedometer',
    class: 'nav-item-inicio'
  },
  {
    name: 'Agenda',
    url:  '/agenda',
    icon: 'fa fa-calendar',
    class: 'nav-item-agenda',
  },
  {
    name: 'Clientes',
    url:  '/cliente/lista',
    icon: 'fa fa-id-card-o',
    class: 'nav-item-cadastro-clientes'
  },
  {
    divider: true
  },
  {
    title: true,
    name: 'Módulos'
  },
  {
    name: 'Cadastro',
    url: '/cadastro',
    icon: 'fa fa-plus-circle',
    class: 'nav-item-cadastros',
    children: [
      {
        name: 'Modalidades',
        url:  '/modalidade/lista',
      },
      {
        name: 'Planos',
        url:  '/plano/lista',
      },
      {
        name: 'Profissionais',
        url:  '/profissional/lista',
      },
      {
        name: 'Cargos',
        url:  '/cargo/lista',
      },
      {
        name: 'Feriados',
        url:  '/feriado/lista',
      },
      {
        name: 'Grupos',
        url: '/controle-acesso/usuarios/grupos',
      }
    ]
  },
  {
    name: 'Evoluções',
    url:  '/avaliacao/evolucao/lista',
    icon: 'fa fa-bar-chart',
    class: 'nav-item-evolucoes'
  },
  {
    name: 'Matrículas',
    url:  '/matriculas/lista-por-aluno',
    icon: 'fa fa-th-list',
    class: 'nav-item-matriculas'
  },
  {
    name: 'Financeiro',
    url: '/financeiro',
    icon: 'fa fa-money',
    class: 'nav-item-financeiro',
    children: [
      {
        name: 'Mensalidades',
        url:  '/financeiro/mensalidades/lista',
        class: 'nav-item-mensalidades'
      },
      {
        name: 'Contas a pagar',
        url:  '/financeiro/contas-pagar/lista',
        class: 'nav-item-contas-pagar'
      },
      {
        name: 'Fechamento comissão',
        url:  '/financeiro/comissao/fechamento-periodo',
        class: 'nav-item-fechamento-comissao'
      },
      {
        name: 'Balanço financeiro',
        url:  '/financeiro/balanco',
        class: 'nav-item-balanco-financeiro'
      }
    ]
  },
  {
    name: 'Relatórios',
    url: '/relatorios',
    icon: 'fa fa-th-list',
    class: 'nav-item-relatorios',
    children: [
      {
        name: 'Relatório de aulas',
        url:  '/agenda/relatorios/aula',
        class: 'nav-item-relatorio-aulas'
      },
      {
        name: 'Alunos experimentais',
        url:  '/agenda/relatorios/experimentais',
        class: 'nav-item-alunos-experimentais'
      },
      {
        name: 'Relatório matrículas',
        url:  '/matriculas/relatorio',
        class: 'nav-item-relatorio-matriculas'
      },
      {
        name: 'Comissões',
        url:  '/financeiro/comissao/por-profissional',
        class: 'nav-item-comissao-profissionais'
      }
    ]
  },
  // {
  //   name: 'Controle de acesso',
  //   url: '/controle-acesso',
  //   icon: 'fa fa-lock',
  //   children: [
  //     // {
  //     //   name: 'Usuários',
  //     //   url:  '/controle-acesso/usuarios/lista',
  //     // },
  //     {
  //       name: 'Grupos',
  //       url: '/controle-acesso/usuarios/grupos',
  //     }
  //   ]
  // }
];
