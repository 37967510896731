import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ComissaoService } from '../../../financeiro/shared/services/comissao-service';
import { ListComponent } from '../../../shared/components';
import { FechamentoComissao } from '../../../financeiro/shared/models/fechamentoComissao';

@Component({
  selector: 'app-perfil-comissao',
  templateUrl: './perfil-comissao.component.html'
})
export class PerfilComissaoComponent extends ListComponent<FechamentoComissao> implements OnInit, OnDestroy {
  public static resourceName = 'RelatorioComissao';
  public customSearchForm: UntypedFormGroup;
  public valorComissoes = 0;
  private valorCarregado: boolean = false;
  public loading: boolean = true;

  constructor(
    protected injector: Injector,
    protected service: ComissaoService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    this.baseListConfig.title = 'MINHAS COMISSÕES';
    this.baseListConfig.icon = 'fa fa-money';

    this.initCustomSearchFormControls();
    super.ngOnInit();
  }

  ngOnDestroy() {

    super.ngOnDestroy();
  }

  public initCustomSearchFormControls() {
    this.customSearchForm = new UntypedFormBuilder().group({
      dataInicio: [],
      dataFim: []
    });
    const today = new Date(),
      curYear = today.getFullYear(),
      curMonth = today.getMonth();

    this.customSearchForm.controls.dataInicio.setValue(new Date(curYear, curMonth, 1));
    this.customSearchForm.controls.dataFim.setValue(new Date(curYear, curMonth + 1, 0));
  }

  public loadList() {
    if (this.customSearchForm.invalid) {
      return;
    }

    // dados básicos
    const formValues = this.customSearchForm.getRawValue();
    const inicio = new Date(formValues.dataInicio).toISOString().split('T')[0].replace(/-/g, "");
    const fim = new Date(formValues.dataFim).toISOString().split('T')[0].replace(/-/g, "");

    // carregar histórico
    const endpoint = `meu-perfil?inicio=${inicio}&fim=${fim}`;
    super.loadList(endpoint);
    
    // carregar comissoes em aberto
    if (!this.valorCarregado) {
      const endpointValores = 'valor-comissoes';
      
      this.service.get(endpointValores).subscribe(res => {
        this.valorComissoes = res.valorComissoes;
        this.valorCarregado = true;
        this.loading = false;
      }, err => {
        
      })
    }
  }

  public totalComission() {
    let value = this.listPartialOfItems.map(item => item.valor);
    let totalComission = value.reduce((acc, valor) => acc + valor, 0);
    return totalComission;
  }
}
