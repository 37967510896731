import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProfileNavItem } from '../../../shared/models';
import { ProfessionalService } from '../../shared';
import { UserService } from '../../../user/shared';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-perfil-menu',
    templateUrl: './perfil-menu.component.html'
})
export class PerfilMenuComponent implements OnInit, OnDestroy {
    @BlockUI() blockui: NgBlockUI;

    public navItemsComissao: ProfileNavItem[] = [
        {
            title: 'Minhas comissões',
            icon: 'fa fa-money',
            url: 'comissao'
        }
    ];

    public navItemsAulas: ProfileNavItem[] = [
        {
            title: 'Minhas aulas',
            icon: 'fa fa-calendar-o',
            url: 'aulas'
        }
    ];

    public showMenuComission: boolean;
    public showMenuClasses: boolean;

    constructor(
        private service: ProfessionalService,
        private userService: UserService,
        private titleService: Title,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.titleService.setTitle('Agenda Pilates - Perfil do profissional');
        if (this.userService.usuarioLogado)
            this.setMenuVisibility();
        else {
            this.blockui.start();

            this.userService.getLoggedUser().subscribe(
                user => {
                    this.userService.usuarioLogado = user;
                    this.setMenuVisibility();
                    this.blockui.stop();
                }, (error) => {
                    this.toastr.error('Não foi possível carregar os dados do instrutor. Recarregue a página e tente novamente.')
                }
            );
        }
    }

    ngOnDestroy() {
        this.service.setCurItem(null);
    }

    setMenuVisibility() {
        this.showMenuComission = this.userService.usuarioLogado?.mostrarPainelInstrutorComissao;
        this.showMenuClasses = this.userService.usuarioLogado?.mostrarPainelInstrutorAulas;
    }
}
