import { AgendaFuncionario } from './AgendaFuncionario';

export class AgendaGeneric {
    id: number;
    data: number;
    hora: number;
    agendaFuncionarios: AgendaFuncionario[];

    constructor() {
        this.agendaFuncionarios = [];
    }
}