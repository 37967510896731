import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FieldConfig } from '../../models';

@Component({
  selector: 'app-input-form',
  templateUrl: './input-form.component.html'
})
export class InputFormComponent {
  @Input() form: UntypedFormGroup;
  @Input() fieldConfig: FieldConfig;

  public fieldTypeMapper = <{[key: string]: string}>{
    integer: 'number',
    double: 'number',
    texto: 'text',
    email: 'email',
    data: 'date'
  };

}
