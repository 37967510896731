import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent {
  public message: string;

  constructor(
    public modal: BsModalRef,
    public modalService: BsModalService,
    private router: Router
  ) {
    const subNavigate = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        modal.hide();
        subNavigate.unsubscribe();
      }
    });
  }

}
