import { Directive, Injector, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { IMensalidade } from '../../models';

import { EditDirective, ModalWindowConfirmComponent } from '../../../../shared/components';

import { MensalidadeService } from '../../services';

@Directive()
export class MensalidadeDetalhesBaseDirective extends EditDirective<IMensalidade> implements OnInit {
    public item: IMensalidade = {} as IMensalidade;
    public visualizarDetalheMatricula: boolean;

    constructor(
        protected injector: Injector,
        protected service: MensalidadeService
    ) {
        super(injector, service);
    }

    public reverse(entityId: number, type: string): void {
        const checkType = type == 'payment';

        const noPermissionMessage = 'Você não tem permissão para Estornar pagamentos.',
            questionMessage = checkType ? 'Tem certeza que deseja Estornar este pagamento?' :
            'Tem certeza que deseja Estornar esta mensalidade?',
            successMessage = checkType ? 'Pagamento estornado com sucesso' : 'Mensalidade estornada com sucesso',
            endpoint = checkType ? 'estornar/pagamento' : 'estornar';

        if (!this.permissions.excluir) {
            this.toastr.warning(noPermissionMessage, 'Atenção');
            return;
        }

        this.modalService.show(ModalWindowConfirmComponent, {
            class: 'modal-md shadow',
            initialState: {
                contentText: questionMessage
            }
        });

        const onHiddenModalSub: Subscription = this.modalService.onHidden.subscribe(reason => {
            if (reason == 'true') {
                this.blockui.start();

                const mensalidade: any = {
                    id: entityId,
                };

                this.service.put(mensalidade, endpoint)
                .pipe(finalize(this.blockui.stop))
                .subscribe(
                    () => {
                        this.toastr.success(successMessage);
                        if (!checkType) {
                            this.modal.hide();
                        }
                    },
                    error => {
                        this.toastr.error(error.message);
                    }
                );
            }

            onHiddenModalSub.unsubscribe();
        });
    }

    public printInvoice(resourceName: string) {
        let endpoint = '';
        const baseHref = document.getElementsByTagName('base')[0].getAttribute('href');
        const componentList = {
            Mensalidade: `${baseHref}print/reports/recibo/mensalidade/${this.item.id}`,
            MensalidadeAluno: `${baseHref}print/reports/recibo/mensalidade-aluno/${this.item.id}`
        };

        if (resourceName in componentList) {
          endpoint = componentList[resourceName];
        } else {
          return;
        }

        const customWindow = window.open(endpoint,
          '_blank', 'top=0,left=0,location=no,status=0,width=' + window.innerWidth + ',height=' + window.innerHeight);
    }

}
