import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { finalize } from 'rxjs/operators';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { EmpresaService } from '../../empresa/shared/services';
import { AuthService } from '../shared';

import { Empresa } from '../../empresa/shared/models';

@Component({
  selector: 'app-seleciona-empresa',
  templateUrl: './seleciona-empresa.component.html',
  styleUrls: ['./seleciona-empresa.component.scss']
})
export class SelecionaEmpresaComponent implements OnInit {
  @BlockUI() blockui: NgBlockUI;
  public formGroup: UntypedFormGroup;

  public empresaList = [] as Empresa[];
  public curEmpresa = {} as Empresa;

  constructor(
    private service: EmpresaService,
    private authService: AuthService,
    private toastr: ToastrService,
    private titleService: Title,
    private router: Router
  ) {
    this.formGroup = new UntypedFormBuilder().group({
      empresaId: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Agenda Pilates - Selecionar empresa');

    this.empresaList = this.service.getAllFromLocalStorage();
    this.curEmpresa = this.service.getFromLocalStorage();

    if (this.curEmpresa && this.curEmpresa.id) {
      this.formGroup.get('empresaId').setValue(this.curEmpresa.id);
    }
  }

  public onSubmitFormGroup(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.blockui.start();

    const selectedEmpresa = this.empresaList.filter(el => el.id == this.formGroup.get('empresaId').value)[0];

    this.authService.empresaLogin(selectedEmpresa)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      token => {
        this.authService.updateNavigatorSessionData(token);
        this.service.setIntoLocalStorage(selectedEmpresa);

        this.router.navigate(['/', 'inicio']);
      },
      error => {
        this.toastr.error(error.message, 'Error');
      }
    );
  }

  public onSearchEmpresa(event): void {
    return;
  }

}
