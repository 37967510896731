import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { CoreService } from '../../shared/core.service';
import { Cliente } from './models';

@Injectable ()
export class ClienteService extends CoreService<Cliente> {
  protected url = this.api.url + 'alunos/';

  constructor(protected injector: Injector) {
    super(injector);
  }

  getByStatus(bool: boolean = true): Observable<Cliente[]> {
    return this.http.get<Cliente[]>(this.url + bool.toString(), this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public save(entity: Cliente): Observable<Cliente> {
    if (entity.id > 0) {
      return this.put(entity);
    } else {
      return this.post(entity);
    }
  }

  public postAlunoSimplificado(entity: Cliente): Observable<Cliente> {
    return this.http.post<Cliente>(this.api.url + 'simplificados', entity, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public getAlunosSimplificado(name: string): Observable<Cliente[]> {
    return this.http.get<Cliente[]>(this.api.url + 'simplificados/' + name, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public putFoto(entity: Cliente): Observable<Cliente> {
    return this.http.put<Cliente>(this.url + 'foto', entity, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public getPhoto(alunoId?: number): Observable<string> {
    const url = this.url + 'foto/' + alunoId.toString(),
    headers = this.getHeaders().headers,
    httpClientOptions = {observer: 'body', responseType: 'text' as 'json', headers: headers};

    return this.http.get<string>(url, httpClientOptions)
    .pipe(
      tap(
        foto => foto,
        error => this.handleError(error)
      )
    );
  }

}
