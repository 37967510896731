export const environment = {
  production: false,
  api: {
    azure: {
      version: '1.0.0',
      enviroment: 'developer',
      authToken: 'nuvol-token',
      authUser: 'nuvol-user',
      // url: 'https://api.agendapilates.com.br/api/' //produção
      // url: 'http://localhost:50804/api/' //local
      url: 'https://agenda-pilates-api-dev.azurewebsites.net/api/' //hml
    },
    firebase: {

    }
  }
};
