import { Injectable, Injector } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AgendaDetalhes, IAgendaDetalhes } from '../models/AgendaDetalhes';
import { AgendaDetalhesSimples, AgendaStatus } from '../models';

import { CoreService } from '../../../shared/core.service';
import { AgendaJustificativa } from '../models/AgendaJustificativa';

const minhaCondicao = localStorage.getItem('isNewAgendaEnabledCache');

const workingHours: {hora: number, descricao: string}[] = minhaCondicao === 'true' ?[
  {hora: 500, descricao: '05:00'},
  {hora: 515, descricao: '05:15'},
  {hora: 530, descricao: '05:30'},
  {hora: 545, descricao: '05:45'},
  {hora: 600, descricao: '06:00'},
  {hora: 615, descricao: '06:15'},
  {hora: 630, descricao: '06:30'},
  {hora: 645, descricao: '06:45'},
  {hora: 700, descricao: '07:00'},
  {hora: 715, descricao: '07:15'},
  {hora: 730, descricao: '07:30'},
  {hora: 745, descricao: '07:45'},
  {hora: 800, descricao: '08:00'},
  {hora: 815, descricao: '08:15'},
  {hora: 830, descricao: '08:30'},
  {hora: 845, descricao: '08:45'},
  {hora: 900, descricao: '09:00'},
  {hora: 915, descricao: '09:15'},
  {hora: 930, descricao: '09:30'},
  {hora: 945, descricao: '09:45'},
  {hora: 1000, descricao: '10:00'},
  {hora: 1015, descricao: '10:15'},
  {hora: 1030, descricao: '10:30'},
  {hora: 1045, descricao: '10:45'},
  {hora: 1100, descricao: '11:00'},
  {hora: 1115, descricao: '11:15'},
  {hora: 1130, descricao: '11:30'},
  {hora: 1145, descricao: '11:45'},
  {hora: 1200, descricao: '12:00'},
  {hora: 1215, descricao: '12:15'},
  {hora: 1230, descricao: '12:30'},
  {hora: 1245, descricao: '12:45'},
  {hora: 1300, descricao: '13:00'},
  {hora: 1315, descricao: '13:15'},
  {hora: 1330, descricao: '13:30'},
  {hora: 1345, descricao: '13:45'},
  {hora: 1400, descricao: '14:00'},
  {hora: 1415, descricao: '14:15'},
  {hora: 1430, descricao: '14:30'},
  {hora: 1445, descricao: '14:45'},
  {hora: 1500, descricao: '15:00'},
  {hora: 1515, descricao: '15:15'},
  {hora: 1530, descricao: '15:30'},
  {hora: 1545, descricao: '15:45'},
  {hora: 1600, descricao: '16:00'},
  {hora: 1615, descricao: '16:15'},
  {hora: 1630, descricao: '16:30'},
  {hora: 1645, descricao: '16:45'},
  {hora: 1700, descricao: '17:00'},
  {hora: 1715, descricao: '17:15'},
  {hora: 1730, descricao: '17:30'},
  {hora: 1745, descricao: '17:45'},
  {hora: 1800, descricao: '18:00'},
  {hora: 1815, descricao: '18:15'},
  {hora: 1830, descricao: '18:30'},
  {hora: 1845, descricao: '18:45'},
  {hora: 1900, descricao: '19:00'},
  {hora: 1915, descricao: '19:15'},
  {hora: 1930, descricao: '19:30'},
  {hora: 1945, descricao: '19:45'},
  {hora: 2000, descricao: '20:00'},
  {hora: 2015, descricao: '20:15'},
  {hora: 2030, descricao: '20:30'},
  {hora: 2045, descricao: '20:45'},
  {hora: 2100, descricao: '21:00'},
  {hora: 2115, descricao: '21:15'},
  {hora: 2130, descricao: '21:30'},
  {hora: 2145, descricao: '21:45'},
  {hora: 2200, descricao: '22:00'},
  {hora: 2215, descricao: '22:15'},
  {hora: 2230, descricao: '22:30'},
  {hora: 2245, descricao: '22:45'},
  {hora: 2300, descricao: '23:00'},
  {hora: 2315, descricao: '23:15'},
  {hora: 2330, descricao: '23:30'},
  {hora: 2345, descricao: '23:45'},
] : [
  {hora: 500, descricao: '05:00'},
  {hora: 600, descricao: '06:00'},
  {hora: 700, descricao: '07:00'},
  {hora: 800, descricao: '08:00'},
  {hora: 900, descricao: '09:00'},
  {hora: 1000, descricao: '10:00'},
  {hora: 1100, descricao: '11:00'},
  {hora: 1200, descricao: '12:00'},
  {hora: 1300, descricao: '13:00'},
  {hora: 1400, descricao: '14:00'},
  {hora: 1500, descricao: '15:00'},
  {hora: 1600, descricao: '16:00'},
  {hora: 1700, descricao: '17:00'},
  {hora: 1800, descricao: '18:00'},
  {hora: 1900, descricao: '19:00'},
  {hora: 2000, descricao: '20:00'},
  {hora: 2100, descricao: '21:00'},
  {hora: 2200, descricao: '22:00'},
  {hora: 2300, descricao: '23:00'},
];

@Injectable()
export class AgendaService extends CoreService<IAgendaDetalhes> {
  private serviceUrl: string = this.api.url + 'agenda';
  protected url: string = this.api.url + 'agenda/';

  private _dataAtual: BehaviorSubject<Date> = new BehaviorSubject(new Date());
  public dataAtual: Observable<Date> = this._dataAtual.asObservable();

  private _visibilidade: BehaviorSubject<string> = new BehaviorSubject('semanal');
  public visibilidade: Observable<string> = this._visibilidade.asObservable();

  private _listAgendaStatus: BehaviorSubject<AgendaStatus[]> = new BehaviorSubject(null);
  public listAgendaStatus: Observable<AgendaStatus[]> = this._listAgendaStatus.asObservable();

  private _curAgendaDetalhes: BehaviorSubject<AgendaDetalhes> = new BehaviorSubject(null);
  public curAgendaDetalhes: Observable<AgendaDetalhes> = this._curAgendaDetalhes.asObservable();

  private _actionAgendaDetalhes: BehaviorSubject<string> = new BehaviorSubject(null);
  public actionAgendaDetalhes: Observable<string> = this._actionAgendaDetalhes.asObservable();

  private workingHours: BehaviorSubject<{hora: number, descricao: string}[]> = new BehaviorSubject(workingHours);
  public listHour: Observable<{hora: number, descricao: string}[]> = this.workingHours.asObservable();

  constructor(protected injector: Injector) {
    super(injector);
   }

   public insert(type: string, agenda: AgendaDetalhesSimples): Observable<any> {
     const url: string = this.serviceUrl + '/' + type;

     return this.http.post<AgendaDetalhes>(url, agenda, this.getHeaders())
     .pipe(
        map(data => {
          data.agendaId = data.id;
          this.setCurAgenda(data);
          this.setAction('update');
          return data;
        }),
        catchError(this.handleError)
     );
   }

   public update(agenda: AgendaDetalhes): Observable<any> {
    return this.http.put<AgendaDetalhes>(this.serviceUrl, agenda, this.getHeaders())
    .pipe(
      map(data => {
        data.agendaId = data.id;
        this.setCurAgenda(data);
        this.setCurItem(data);
        this.setAction('update');
       return data;
      }),
      catchError(this.handleError)
    );
   }

   public corrigir(agenda: AgendaDetalhes): Observable<any> {
    return this.http.put<AgendaDetalhes>(this.serviceUrl + '/corrigir', agenda, this.getHeaders())
    .pipe(
      map(data => {
        data.agendaId = data.id;
        this.setCurAgenda(data);
        this.setCurItem(data);
        this.setAction('update');
       return data;
      }),
      catchError(this.handleError)
    );
  }
  public descancelar(agendaDetalhes: AgendaDetalhes) {
    const url: string = this.serviceUrl + '/descancelar/' + agendaDetalhes.id;
    return this.http.put<AgendaDetalhes>(url, agendaDetalhes.cancelado, this.getHeaders()).pipe(map(data => agendaDetalhes.cancelado = data.cancelado)).subscribe();
  }


   public buscar(route: string): Observable<any> {
     const url: string = this.serviceUrl + '/' + route;

     return this.http.get(url, this.getHeaders())
     .pipe(
        map(data => data),
        catchError(this.handleError)
     );
   }

   public getById(id: number): Observable<AgendaDetalhes> {
     const url = this.serviceUrl + '/' + id;

     return this.http.get<AgendaDetalhes>(url, this.getHeaders())
     .pipe(
        map(data => data),
        catchError(this.handleError)
     );
   }

   public getStatusAgenda(): Observable<AgendaStatus[]> {
     const url = this.serviceUrl + '/status';

     return this.http.get<AgendaStatus[]>(url, this.getHeaders())
     .pipe(
        map(data => {
          this._listAgendaStatus.next(data);
          return data;
        }),
        catchError(this.handleError)
     );
   }

  public updateStatusAgenda(status: string, agendaId: number, mensagem: string): Observable<AgendaDetalhesSimples> {
    const url = this.serviceUrl + '/' + status;
    const agendaObj = { id: agendaId, justificativa: mensagem };

    return this.http.put<AgendaDetalhesSimples>(url, agendaObj, this.getHeaders())
    .pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public deleteAgendaExperimental(agendaDetalhes: AgendaDetalhes) {
    const url = this.serviceUrl + '/' + agendaDetalhes.agendaId;

    return this.http.delete<AgendaDetalhes>(url, this.getHeaders())
    .pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  public updateAgendaJustificativa(agendaDetalhes: AgendaJustificativa) {
    const url = this.serviceUrl + '/justificado';
    return this.http.put<AgendaDetalhes>(url, agendaDetalhes, this.getHeaders())
    .pipe(
      map(data => {
        this.setCurItem(data);
        this.setAction('update');
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public cancelAgenda(agendaDetalhes: AgendaDetalhes) {
    const url = this.url + 'cancelar/' + agendaDetalhes.id.toString();

    return this.http.delete<AgendaDetalhes>(url, this.getHeaders())
    .pipe(
      map(data => {
        this.setCurItem(data);
        this.setAction('update');

        return data;
      }),
      catchError(this.handleError)
    );
  }

   public setDataAtual(data: Date) {
     this._dataAtual.next(data);
     return;
   }

   public setVisibilidade(str: string) {
     this._visibilidade.next(str);
   }

   public setCurAgenda(agendaDetalhes?: AgendaDetalhes): void {
     this._curAgendaDetalhes.next(agendaDetalhes);
   }

   public setAction(action: string) {
    this._actionAgendaDetalhes.next(action);
    super.setAction(action);
  }
}
