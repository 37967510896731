import { Component, Injector, Input, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ListComponent, ModalWindowConfirmComponent } from '../../../../shared/components';
import { AgendaFormEditComponent } from '../agenda-form-edit';
import { MatriculaFormEditComponent } from '../../../../matriculas/shared/components';

import { Matricula } from '../../../../matriculas/shared/models';
import { IAgendaDetalhes } from '../../models';
import { Permissoes } from '../../../../auth/shared/models';

import { AgendaService } from '../../services';
import { MatriculaService } from '../../../../matriculas/shared';
import { DateAgendaCustomPipe } from '../../../../shared/pipes';
import { UserService } from '../../../../user/shared';


import { HttpClient } from '@angular/common/http';
import { DownloadFilesService } from '../../../../shared/download-files.service';



@Component({
  selector: 'app-lista-agenda-clientes',
  templateUrl: './lista-agenda-clientes.component.html',
  styleUrls: ['./lista-agenda-clientes.component.scss']
})
export class ListaAgendaClientesComponent extends ListComponent<IAgendaDetalhes> implements OnInit, OnDestroy {
  public static resourceName = 'Agenda';

  @Input() matricula: Matricula;

  private corrigirAgendaPermissions = {} as Permissoes;
  private trocarHorarioPermissao = {} as Permissoes;
  private relatorioAulaPermissoes = {} as Permissoes;

  public monthList: string[] = [];
  public selectedMonth = '';

  public formComponent = AgendaFormEditComponent;
  public formComponentClass = 'modal-md';

  private actionMatriculaSub: Subscription = new Subscription();
  private loggedUserSub: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    protected injector: Injector,
    protected service: AgendaService,
    private matriculaService: MatriculaService,
    private dateAgendaCustomPipe: DateAgendaCustomPipe,
    private downloadService: DownloadFilesService
  ) {
    super(injector, service);

    this.corrigirAgendaPermissions = this.authService.getPermissions('CorrigirAgenda');
    this.relatorioAulaPermissoes = this.authService.getPermissions('RelatorioAula');
    this.trocarHorarioPermissao = this.authService.getPermissions('AlterarHorario');

    this.baseListConfig.title = 'Agendas da Matrícula';
    this.baseListConfig.icon = 'fa fa-calendar';
    this.baseListConfig.printButtonIsShown = true;
    this.baseListConfig.exportExcelButtonIsShown = true;
    this.baseListConfig.exportExcelButtonTitle = "Exportar aulas";
    this.baseListConfig.exportExcelFunction = this.getPlanilhaAula;
  }

  ngOnInit() {
    this.actionMatriculaSub = this.matriculaService.action.subscribe(action => {
      if (action && action.match(/delete|update/i)) {
        this.loadList();
      }
    });

    super.ngOnInit();

    this.baseListConfig.customButton = [];

    this.baseListConfig.customButton.push(
      {
        title: 'Alterar Horários',
        class: 'btn btn-secondary text-app-primary ml-2 float-right',
        icon: 'fa fa-exchange',
        method: () => this.openDialogAlterarHorarios()
      }
    );

  }

  ngOnDestroy() {
    this.actionMatriculaSub.unsubscribe();
    this.service.setCurAgenda(null);

    super.ngOnDestroy();
  }

  public loadList() {
    const endpoint = 'buscar/' + String(this.matricula.id);

    this.blockui.start();

    this.selectedMonth = '';
    this.baseListConfig.pagination.enabled = true;

    this.service.getList(endpoint)
      .pipe(finalize(this.blockui.stop))
      .subscribe(
        data => {
          if (!data || data.length == 0) {
            this.emptyList = true;
            this.listOfItem = [];
            return;
          }

          data.map(element => element.mes = this.dateAgendaCustomPipe.transform(element.data, 'MMMM') + '/' +
            this.dateAgendaCustomPipe.transform(element.data, 'yyyy'));

          this.listOfItem = data;
          this.listPartialOfItems = this.listOfItem.slice(0, this.baseListConfig.pagination.itemsPerPage);
          this.currentPage = 1;

          this.makeMonthList(data);
        },
        error => {
          this.toastr.error(error.message, 'Error');
        }
      );
  }

  public openItemDialog(agenda: IAgendaDetalhes) {
    if (!this.corrigirAgendaPermissions.atualizar && !this.corrigirAgendaPermissions.excluir) {
      return;
    }

    if (!agenda.aluno && !agenda.matricula && !agenda.funcionario) {
      return;
    }

    // if (this.matricula.cancelado || this.matricula.trancado || agenda.cancelado || agenda.statusAgenda.sigla == 'TR') {
    //   return;
    // }
    if (agenda.cancelado) {
        this.blockui.start();
        this.modalService.show(ModalWindowConfirmComponent, {
          class: 'modal-centered modal-app-sencondary',
          initialState: {
            dialogTitle: 'Atenção',
            contentText: 'Deseja alterar o status de Cancelado desta agenda?'
          }
        });
        const modalSub = this.modalService.onHidden.subscribe(action => {

          if (action && action == 'true') {
            this.service.descancelar(agenda);
          }
          modalSub.unsubscribe();

        })
      this.blockui.stop();
      return agenda;
    }

    if (this.matricula.trancado || agenda.statusAgenda.sigla == 'TR') {
      return;
    }

    this.service.setCurItem(null);

    this.modalService.show(this.formComponent, {
      class: this.formComponentClass,
      backdrop: 'static',
      animated: false,
      initialState: {
        item: agenda
      }
    });
  }

  public getColorStatusAgenda(statusAgenda?: string): string {
    if (!statusAgenda) { return ''; }

    const status = [
      { sigla: 'EX', class: 'badge badge-info' },
      { sigla: 'HF', class: 'horario-livre' },
      { sigla: 'PR', class: 'badge badge-success' },
      { sigla: 'JU', class: 'badge badge-secondary text-line-through' },
      { sigla: 'FA', class: 'badge badge-danger' },
      { sigla: 'RE', class: 'badge badge-warning' },
      { sigla: 'HL', class: 'badge badge-primary' },
      { sigla: 'TR', class: 'badge badge-dark' }
    ];

    const objStatus = status.filter(element => element.sigla == statusAgenda)[0];
    return objStatus.class;
  }

  public openDialogAlterarHorarios() {
    if (!this.trocarHorarioPermissao.atualizar) {
      this.toastr.warning('Você não tem permissão para Alterar horários.', 'Atenção');
      return;
    }

    this.modalService.show(MatriculaFormEditComponent, {
      class: 'modal-lg',
      backdrop: 'static',
      initialState: {
        item: this.matricula
      }
    });
  }

  public filterByMonth(month?: string): void {
    if (month && month.length) {
      this.selectedMonth = month;
      this.baseListConfig.pagination.enabled = false;

      this.listPartialOfItems = this.listOfItem.filter(el => el.mes == month);
    } else {
      this.selectedMonth = '';
      this.baseListConfig.pagination.enabled = true;

      this.listPartialOfItems = this.listOfItem.slice(0, this.baseListConfig.pagination.itemsPerPage);
      this.currentPage = 1;
    }

  }

  private makeMonthList(data: IAgendaDetalhes[]): void {
    const tmpMonth: string[] = [];

    data.forEach(element => {
      tmpMonth.push(element.mes);
    });

    const distinct = (v, k, s) => {
      return s.indexOf(v) === k;
    };

    this.monthList = tmpMonth.filter(distinct);
  }

  public printPage() {
    if (!this.relatorioAulaPermissoes.visualizar) {
      this.toastr.warning('Você não tem permissão para Visualizar o relatório.', 'Atenção');
      return;
    }

    const endpoint = `print/reports/matricula/aulas-aluno/${this.matricula.id}`;

    const baseHref = document.getElementsByTagName('base')[0].getAttribute('href');

    const customWindow = window.open(baseHref + endpoint,
      '_blank', 'top=0,left=0,location=no,status=0,width=' + window.innerWidth + ',height=' + window.innerHeight);
  }

  public getPlanilhaAula() {
    const urlRelativa = `excel-aulas-por-matricula/${this.matricula.id}`;
    this.downloadService.exportar(urlRelativa, "PlanilhaAulas");
  }
  
  
  formatarHora(agendaHora: number): string {
    let hora: number;
    let minutos: number;
  
    if (agendaHora < 100) {
      // Exemplo: '8' -> '08:00' ou '10' -> '10:00'
      hora = agendaHora;
      minutos = 0;
    } else {
      // Exemplo: '745' -> '07:45' ou '1830' -> '18:30' ou '2015' -> '20:15'
      hora = Math.floor(agendaHora / 100);
      minutos = agendaHora % 100;
    }
  
    // Formatação com zero à esquerda para horas e minutos
    const horaFormatada = hora < 10 ? `0${hora}` : `${hora}`;
    const minutosFormatados = minutos < 10 ? `0${minutos}` : `${minutos}`;
  
    return `${horaFormatada}:${minutosFormatados}`;
  }
  
  
  
  
}
