// src/app/views/service/config-service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public isNewAgendaEnabled: boolean = false; // valor padrão

  constructor(private http: HttpClient) { }

  async loadConfig(): Promise<void> {
    if (window.location.hostname === "spa.agendapilates.com.br") {
      window.location.href = "https://spa2.agendapilates.com.br" + window.location.pathname + window.location.search;
      return;
    }

    const empresaTokenStr = localStorage.getItem('nuvol-token-empresa-developer');
    let empresaId = '';

    if (empresaTokenStr) {
      try {
        const empresaToken = JSON.parse(empresaTokenStr);
        if (empresaToken && empresaToken.id) {
          empresaId = empresaToken.id;
        }
      } catch (error) {
        console.error('Erro ao parsear nuvol-token-empresa-developer:', error);
      }
    }

    if (!empresaId) {
      empresaId = 'valorDefaultOuTratarErro';
    }

    const tokenStr = localStorage.getItem('nuvol-token-developer');
    let accessToken = '';

    if (tokenStr) {
      try {
        const tokenObj = JSON.parse(tokenStr);
        accessToken = tokenObj.access_token;
      } catch (error) {
        console.error('Erro ao parsear nuvol-token-developer:', error);
      }
    }

    const configUrl = `${environment.api.azure.url}empresas/${empresaId}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });

    try {
      const config = await this.http.get<{ novoLayoutHabilitado: boolean }>(configUrl, { headers }).toPromise();

      if (config) {
        this.isNewAgendaEnabled = config.novoLayoutHabilitado;
        localStorage.setItem('isNewAgendaEnabledCache', this.isNewAgendaEnabled.toString());
      }

    } catch (error) {
      console.error('Erro ao carregar a configuração:', error);
      this.isNewAgendaEnabled = false;
    }
  }
}
