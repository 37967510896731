import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  template: '<block-ui><router-outlet></router-outlet></block-ui>',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
