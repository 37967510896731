import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConfigService } from './views/service/config-service';
import { getRoutes } from './app-routing.module'; // Certifique-se de exportar essa função do app-routing.module
import { setTheme } from 'ngx-bootstrap/utils';

@Component({
  selector: 'body',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private configService: ConfigService
  ) {
    setTheme('bs4');
  }

  ngOnInit(): void {
    // Aqui, como o APP_INITIALIZER aguarda o loadConfig(),
    // o valor de configService.isNewAgendaEnabled já deverá estar definido.
    const routes = getRoutes(this.configService.isNewAgendaEnabled);
    this.router.resetConfig(routes);

    // Lógica existente para scroll ao navegar
    this.router.events.subscribe((ev) => {
      if (!(ev instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
