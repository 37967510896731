import { Component, Input } from '@angular/core';

import { AgendaFechamentoComissao } from '../../../../agenda/shared/models';

@Component({
  selector: 'app-tabela-lista-aula-comissao',
  templateUrl: './tabela-lista-aula-comissao.component.html',
  styleUrls: ['./tabela-lista-aula-comissao.component.scss']
})
export class TabelaListaAulaComissaoComponent {
  @Input() public listaAulas: AgendaFechamentoComissao[] = [];
  @Input() public tipoComissaoId = 1;

}
