import { Injectable, Injector } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';

import { CoreService } from '../../shared/core.service';
import { EmpresaService } from '../../empresa/shared/services';

import { Login, AppSession, Recurso, AuthUser, Permissao, Permissoes, Registro } from './models';
import { Empresa } from '../../empresa/shared/models';
import { environment } from '../../../../environments/environment';
import {AgendaDetalhes} from '../../agenda/shared/models';

@Injectable()
export class AuthService extends CoreService<AppSession> {

  private _currentSession: BehaviorSubject<AppSession> = new BehaviorSubject(null);
  public currentSession: Observable<AppSession> = this._currentSession.asObservable();

  private _invalidToken: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public invalidToken: Observable<boolean> = this._invalidToken.asObservable();

  public resourceList = [] as Recurso[];
  private currentUserChange = new Subject<AuthUser>();
  public onCurrentUserChange = this.currentUserChange.asObservable();
  public currentUser: AuthUser = {} as AuthUser;

  constructor(
    private empresaService: EmpresaService,
    protected injector: Injector
    ) {
    super(injector);
  }

  public validarEmailAssinatura(registro: Registro, hash: string): Observable<boolean> {
    const header: HttpHeaders = new HttpHeaders().set('HashConfirmacao', hash);
    const requestOptions = { headers: header };

    return this.http.put<boolean>(`${this.api.url}assinaturas`, registro, requestOptions)
    .pipe(
      map(bool => bool),
      catchError(this.handleError)
    );
  }

  public recuperarSenha(registro: Registro, hash: string): Observable<boolean> {
    const header: HttpHeaders = new HttpHeaders().set('RecaptchaToken', hash);
    const requestOptions = { headers: header };

    return this.http.post<boolean>(`${this.api.url}security/gerar-codigo`, registro, requestOptions)
    .pipe(
      map(bool => bool),
      catchError(this.handleError)
    );
  }

  public validateCode(codigo: string, hash: string): Observable<any> {
    const url: string = this.api.url + "security/validar-codigo";
    return this.http.post(url, { codigo, hash }, this.getHeaders())
  }

  public validateNewPasswordRecovery(codigo: string, hash: string, senha: string, confirmacaoSenha: string): Observable<any> {
    const url: string = this.api.url + "security/alterar-senha";
    return this.http.post(url, { codigo, hash, senha, confirmacaoSenha }, this.getHeaders())
  }

  public async findResources() {
    const lst = await this.http.get(`${this.api.url}recursos`, this.getHeaders())
      .toPromise();
    this.currentUser = this.getCurUserFromToken();
    return this.resourceList = (<Recurso[]>lst);
  }

  private getCurUserFromToken(): AuthUser {
    const localStorageItem = `${this.api.authToken}-${this.api.enviroment}`;
    const token = JSON.parse(atob(JSON.parse(localStorage.getItem(localStorageItem))['access_token'].toString().split('.')[1]));

    return { name: token['unique_name'], permissions: token['permissoes'] } as AuthUser;
  }

  private getPermissionValue(resourceName: string): number {
    const resource = this.resourceList.find(el => el.nome == resourceName);
    if (!resource) {
      return 0;
    }
    return +((new RegExp(`\\|${resource.id}:(\\d+)`)).exec(this.currentUser.permissions) || [])[1];
  }

  public hasPermission(resourceName: string, permission: Permissao) {
    if (!this.currentUser.permissions) {
      return false;
    }

    const value = this.getPermissionValue(resourceName);
    if (value) {
      // tslint:disable-next-line:no-bitwise
      return !!(value & permission.valueOf());
    }
    return false;
  }

  public getPermissions(resourceName: string): Permissoes {
    const value = this.getPermissionValue(resourceName);
    return {
      // tslint:disable:no-bitwise
      visualizar: !!(value & Permissao.visualizar),
      inserir: !!(value & Permissao.inserir),
      atualizar: !!(value & Permissao.atualizar),
      excluir: !!(value & Permissao.excluir),
      // tslint:enable:no-bitwise
    };
  }

  public emailLogin(login: Login): Observable<AppSession> {
    const url: string = this.api.url + 'security/token',
      body: string = 'grant_type=password&username=' + login.username + '&password=' + login.password;

    const  httpHeaders: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const requestOptions = {headers: httpHeaders};

    return this.http.post<AppSession>(url, body, requestOptions)
    .pipe(
      map(data => {
        const appSession: AppSession = data;

        if (appSession.hasOwnProperty('access_token')) {
          appSession.create_at = Math.round(new Date().getTime());
          appSession.expiration_date = new Date(appSession.expiration).getTime();

          this._currentSession.next(appSession);
          this.setNavigatorSessionData(appSession);

          this.currentUser = this.getCurUserFromToken();
          this.currentUserChange.next(this.getCurUserFromToken());

          return appSession;
        }

      }),
      catchError(this.handleError)
    );
  }

  public empresaLogin(empresa: Empresa): Observable<AppSession> {
    const url = this.api.url + 'security/empresa/' + empresa.id as string;

    return this.http.get<AppSession>(url, this.getHeaders())
    .pipe(
      delay(2000),
      map(data => data),
      catchError(this.handleError)
    );
  }

  public logout() {
    window.localStorage.removeItem(`${environment.api.azure.authToken}-${environment.api.azure.enviroment}`);

    this._invalidToken.next(false);

    this._currentSession.next(null);
    this.currentUserChange.next(null);
  }

  public authState(): boolean {
    const currentEmpresa = this.empresaService.getFromLocalStorage();

    if (this.getNavigatorSessionData() && currentEmpresa.id) {
      const current_timestamp = Math.round(new Date().getTime());
      const currentSessionData = this.getNavigatorSessionData();

      if (currentSessionData.expiration_date > current_timestamp) {
        this._invalidToken.next(false);
        return true;
      } else {
        this._invalidToken.next(true);
        return false;
      }
    } else {
      return false;
    }

  }

  private setNavigatorSessionData(appSession: AppSession) {
    if (!appSession) {
      this.logout();

      return false;
    }

    const navigatorSessionData = this.api.authToken + '-' + this.api.enviroment;

    window.localStorage.setItem(navigatorSessionData, JSON.stringify(appSession));

  }

  public updateNavigatorSessionData(appSession: AppSession): void {
    const curSessionData = this.getNavigatorSessionData() as AppSession;

    if (curSessionData) {
      curSessionData.access_token = appSession.access_token;
      curSessionData.expiration = appSession.expiration;

      this.setNavigatorSessionData(curSessionData);
    }
  }

}
