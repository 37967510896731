import { AgendaStatus } from './AgendaStatus';
import { Modalidade } from '../../../modalidades/shared/models';
import { Cliente } from '../../../cliente/shared/models';
import { Professional } from '../../../professional/shared/models';

export interface IAgendaDetalhes {
    id: number;
    data: Date;
    hora: number;
    agendaId: number;
    cancelado: boolean;
    feriado: boolean;
    nomeAluno: string;
    matricula: any;
    dataFinalPlano: Date;
    modalidade: Modalidade;
    funcionario: Professional;
    statusAgenda: AgendaStatus;
    matriculaId: number;
    statusAgendaId: number;
    reposicaoId: number;
    alunoId: number;
    funcionarioId: number;
    eReposicao: boolean;
    foto: string;
    aluno: Cliente;
    mes: string;
    justificativa: string;
    justificar: boolean;
    reagendar: boolean;
    justificativasRestantes: number;
    justificativasAtuais: number;
}

export class AgendaDetalhes implements IAgendaDetalhes {
    justificar: boolean;
    reagendar: boolean;
    justificativasRestantes: number;
    justificativasAtuais: number;
    id: number;
    data: Date;
    hora: number;
    agendaId: number;
    cancelado: boolean;
    feriado: boolean;
    nomeAluno: string;
    matricula: any;
    dataFinalPlano: Date;
    modalidade: Modalidade = <Modalidade>{};
    funcionario: Professional = <Professional>{};
    statusAgenda: AgendaStatus = new AgendaStatus();
    matriculaId: number;
    statusAgendaId: number;
    reposicaoId: number;
    alunoId: number;
    funcionarioId: number;
    eReposicao: boolean;
    foto: string;
    aluno: Cliente;
    mes: string;
    justificativa: string;
    constructor() {
        this.agendaId = 0;
        this.id = 0;
        this.eReposicao = false;
    }
}
