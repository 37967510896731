import { Injectable, Injector } from '@angular/core';

import { CoreService } from '../../shared/core.service';

import { Param } from './models';

@Injectable()
export class ParamService extends CoreService<Param> {
    protected url = this.api.url + 'parametros/';

    constructor(
        protected injector: Injector,
    ) {
        super(injector);
    }
}
