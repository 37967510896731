import { Component, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';

import { Subscription } from 'rxjs';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirma-fechamento-comissao',
  templateUrl: './confirma-fechamento-comissao.component.html',
  styleUrls: ['./confirma-fechamento-comissao.component.scss']
})
export class ConfirmaFechamentoComissaoComponent implements OnDestroy {
  public customFormGroup: UntypedFormGroup;

  public saveButton = true;
  public saveButtonLabel = 'Confirmar';
  public showFooter = true;

  private subChangeRouter: Subscription = new Subscription();

  constructor(
    public modal: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.customFormGroup = new UntypedFormBuilder().group({
      dataPagamento: [new Date(), Validators.required]
    });

    this.subChangeRouter = router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.modal.hide();
      }
    });
  }

  ngOnDestroy() {
    this.subChangeRouter.unsubscribe();
  }

  public save(): void {
    if (this.customFormGroup.invalid) {
      this.toastr.warning('Verifique se a data de fechamento está correta', 'Atenção');
      return;
    }

    const dataPagamento = new Date(this.customFormGroup.controls.dataPagamento.value).toDateString();

    this.modalService.setDismissReason(dataPagamento);
    this.modal.hide();
    return;
  }

}
