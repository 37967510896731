import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateAgendaCustom'
})

export class DateAgendaCustomPipe implements PipeTransform {
    private datePipe: DatePipe = new DatePipe('pt-br');

    transform(value: any, ...args: any[]): any {
        if (!value || value == null) {
            return;
        }

        if ((typeof value).match(/string|object/g) && value.toString().length > 10) {
            if (value.toString().match(/Z/g)) {
                value = value && value.slice(0, -1);
            }

            if (args.length) {
                return this.datePipe.transform(value, args.toString());
            } else {
                return this.datePipe.transform(value, 'dd/MM/yyyy');
            }
        }

        const ano = Number(value.toString().substr(0, 4)),
            mes = Number(value.toString().substr(4, 2)) - 1,
            dia = Number(value.toString().substr(6, 2));

        if (args.length) {
            return this.datePipe.transform(new Date(ano, mes, dia, 0), args.toString());
        } else {
            return this.datePipe.transform(new Date(ano, mes, dia, 0), 'dd/MM/yyyy');
        }
    }
}
