import { Component, OnInit, AfterViewInit,
   ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { finalize } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

import { Login } from '../shared/models';

import { AuthService } from '../shared';
import { EmpresaService } from '../../empresa/shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  @BlockUI() public blockui: NgBlockUI;
  @ViewChildren('userEmail') public userEmail: QueryList<ElementRef>;

  public form: UntypedFormGroup;
  public formSubmitted: boolean;

  constructor(
    private authService: AuthService,
    private empresaService: EmpresaService,
    private toastr: ToastrService,
    private router: Router,
    private titleService: Title
  ) {
    this.form = new UntypedFormBuilder().group({
      userEmail: [null, [Validators.required, Validators.email]],
      userPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Agenda Pilates - Login');

    if (this.authService.authState()) {
      this.router.navigate(['/', 'agenda', 'agenda']);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.userEmail.first.nativeElement.focus();
    }, 500);

  }

  public emailLogin() {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    this.blockui.start();

    const values = this.form.getRawValue();
    const login: Login = {username: values.userEmail, password: values.userPassword};

    this.authService.emailLogin(login)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      sessionData => {
        this.form.reset();

        if (sessionData.empresas.length == 1) {
          this.empresaService.setIntoLocalStorage(sessionData.empresas[0]);

          this.router.navigate(['/inicio']);
        } else if (!sessionData.empresas.length) {
          this.toastr.warning('Você não está vinculado a nenhuma empresa.', 'Atenção');
          this.authService.logout();
          return;
        } else {
          this.router.navigate(['/', 'auth', 'seleciona-empresa']);
        }
      },
      error => {
        this.toastr.error(error.message, 'Erro');
      }
    );
  }

}
