import { Component, Input, Injector, OnInit, OnDestroy } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ListComponent, ModalWindowConfirmComponent } from '../../../../shared/components';

import { Reposicao, ListaReposicaoMatricula } from '../../models';
import { Cliente } from '../../../../cliente/shared/models';
import { LoggedUser } from '../../../../user/shared/models';

import { ReposicaoService } from '../../reposicao.service';
import { UserService } from '../../../../user/shared';

@Component({
  selector: 'app-lista-reposicao-cliente',
  templateUrl: './lista-reposicao-cliente.component.html',
  styleUrls: ['./lista-reposicao-cliente.component.scss']
})
export class ListaReposicaoClienteComponent extends ListComponent<Reposicao> implements OnInit, OnDestroy {
  public static resourceName = 'Reposicao';

  @Input() cliente: Cliente;
  public customClass = 'customClass';

  // @ts-ignore
  public listOfItem: ListaReposicaoMatricula[] = [];

  public loggedUser: LoggedUser = <LoggedUser>{};

  private loggedUserSub: Subscription = new Subscription();

  constructor(
    injector: Injector,
    protected service: ReposicaoService,
    private userService: UserService,
  ) {
    super(injector, service);

    this.baseListConfig.title = 'Reposições em Aberto';
    this.baseListConfig.icon = 'fa fa-calendar-o';

    this.baseListConfig.pagination.itemsPerPage = 500;
  }

  ngOnInit() {
    this.userService.loggedUser.subscribe(user => {
      this.loggedUser = user;
    });

    super.ngOnInit();
  }

  ngOnDestroy() {
    this.loggedUserSub.unsubscribe();

    super.ngOnDestroy();
  }

  loadList() {
    const endpoint = 'aluno/' + String(this.cliente.id);
    super.loadList(endpoint);
  }

  public openDialogCancel(matriculaId: number, reposicaoId: number) {
    if (!this.permissions.excluir) {
      this.toastr.warning('Você não tem permissão para Cancelar reposição.', 'Atenção');
      return;
    }

    this.modalService.show(ModalWindowConfirmComponent, {
      class: 'modal-app-secondary',
      backdrop: 'static',
      initialState: {
        dialogTitle: 'Atenção',
        contentText: 'Tem certeza que deseja cancelar esta reposição?'
      }
    });

    const modalSub = this.modalService.onHidden.subscribe((action: string) => {
      if (action && action == 'true') {
        const reposicoes = [reposicaoId];
        this.cancelReposicao(matriculaId, reposicoes);
      } else {
        this.toastr.info('Ação cancelada pelo Usuário!', 'Informação');
      }

      modalSub.unsubscribe();
    });
  }

  public openDialogBulkCancel(matriculaId: number) {
    const listaReposicaoMatricula = this.listOfItem.filter(el => el.matriculaId == matriculaId)[0];
    const reposicaoMatricula = listaReposicaoMatricula.agendamentos.filter(el => el.checkedToCancel && !el.cancelado);

    if (reposicaoMatricula.length == 0) {
      return;
    }

    if (!this.permissions.excluir) {
      this.toastr.warning('Você não tem permissão para Cancelar reposição.', 'Atenção');
      return;
    }

    this.modalService.show(ModalWindowConfirmComponent, {
      class: 'modal-app-secondary',
      backdrop: 'static',
      initialState: {
        dialogTitle: 'Atenção',
        contentText: 'Tem certeza que deseja cancelar as reposições selecionadas?'
      }
    });

    const modalSub = this.modalService.onHidden.subscribe((action: string) => {
      if (action && action == 'true') {
        const reposicoes: number[] = [];

        reposicaoMatricula.forEach(el => reposicoes.push(el.reposicaoId));

        this.cancelReposicao(matriculaId, reposicoes);
      } else {
        this.toastr.info('Ação cancelada pelo Usuário!', 'Informação');
      }

      modalSub.unsubscribe();
    });
  }

  public cancelReposicao(matriculaId: number, ids: number[]) {
    this.blockui.start();

    const successMessage = ids.length > 1 ? 'As reposições foram canceladas com sucesso!' :
     'Reposição cancelada com sucesso!';

    this.service.bulkCancel(ids)
    .pipe(finalize(this.blockui.stop))
    .subscribe(
      (data) => {
        const reposicoes = this.listOfItem.filter(el => el.matriculaId == matriculaId)[0];

        data.forEach(cancelled => {
          reposicoes.agendamentos.map(
            el => el.cancelado = (el.reposicaoId == cancelled.reposicaoId && cancelled.cancelado) ? true : el.cancelado);
        });

        this.toastr.success(successMessage);
      },
      error => {
        this.toastr.error(error.message, 'Erro');
      }
    );
  }

  public checkAll(matriculaId: number) {
    const reposicaoMatricula: ListaReposicaoMatricula = this.listOfItem.filter(el => el.matriculaId == matriculaId)[0];

    reposicaoMatricula.checkedToCancel = !reposicaoMatricula.checkedToCancel;

    reposicaoMatricula.agendamentos.map(el => el.checkedToCancel = !el.cancelado ? reposicaoMatricula.checkedToCancel : el.cancelado);
  }

  public checkReposicao(matriculaId: number, reposicaoId: number) {
    const reposicaoMatricula: ListaReposicaoMatricula = this.listOfItem.filter(el => el.matriculaId == matriculaId)[0];
    const reposicao = reposicaoMatricula.agendamentos.filter(el => el.reposicaoId == reposicaoId)[0];

    reposicao.checkedToCancel = !reposicao.checkedToCancel;

    reposicaoMatricula.checkedToCancel =
     reposicaoMatricula.agendamentos
     .filter(el => el.checkedToCancel == true && !el.cancelado).length ==
      reposicaoMatricula.agendamentos
      .filter(el => !el.cancelado).length;
  }

}
