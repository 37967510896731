export class AgendaDetalhesSimples {
    hora: string;
    agendaId: string;
    statusAgenda: string;
    eReposicao: boolean;
    alunoId: number;
    funcionarioId: number;
    matriculaId: number;
    modalidadeId: number;
    reposicaoId: number;
    data: string;
}
