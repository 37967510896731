import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-termos-adesao',
  templateUrl: './termos-adesao.component.html',
  styleUrls: ['./termos-adesao.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermosAdesaoComponent implements OnInit {

  constructor(
    private titleService: Title
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Agenda Pilates - Termos de adesão do serviço');
  }

}
