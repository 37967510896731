import { Component, ViewChild } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

import { AlertMessageComponent } from '../alert-message';

@Component({
  selector: 'app-image-cropping',
  templateUrl: './image-cropping.component.html',
  styleUrls: ['./image-cropping.component.scss'],
})
export class ImageCroppingComponent {
  public title: string;
  public saveButton = true;
  public saveButtonLabel = 'Enviar';
  public showFooter = true;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;

  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  constructor(
    public modal: BsModalRef,
    private modalService: BsModalService
  ) { }

  public save(): void {
    this.modalService.setDismissReason(this.croppedImage);
    this.modal.hide();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  loadImageFailed () {
    this.modalService.show(AlertMessageComponent, {
      class: 'modal-sm',
      initialState: {
        message: 'Erro ao abrir o arquivo! Tente novamente com outra imagem.'
      }
    });
  }

  rotateLeft() {
    this.imageCropper.rotateLeft();
  }

  rotateRight() {
    this.imageCropper.rotateRight();
  }

  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }

  flipVertical() {
    this.imageCropper.flipVertical();
  }

}
