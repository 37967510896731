import { Component, Injector } from '@angular/core';

import { MensalidadeFormBaseDirective } from './mensalidade-form-base.directive';
import { MatriculaDetalhesComponent } from '../../../../matriculas/shared/components';

import { MensalidadeService } from '../../services';

import { Permissoes } from '../../../../auth/shared/models';
import { Matricula } from '../../../../matriculas/shared/models';
import { Plano } from '../../../../plano/shared/models';

@Component({
  selector: 'app-mensalidade-form',
  templateUrl: './mensalidade-form-layout.component.html',
  styleUrls: ['./mensalidade-form-layout.component.scss']
})

export class MensalidadeFormComponent extends MensalidadeFormBaseDirective {
  public static resourceName = 'Mensalidade';
  public visualizarDetalheMatricula = true;
  public matriculaPermissions: Permissoes;

  constructor(
    protected service: MensalidadeService,
    protected injector: Injector
  ) {
    super(injector, service);

    this.matriculaPermissions = this.authService.getPermissions('Matricula');

    this.getPaymentMethods();
  }

  private getPaymentMethods(): void {
    this.service.paymentMethods().subscribe(
      paymentMethods => {
        this.listaFormaPagamento = paymentMethods;
      }
    );
  }

  protected printInvoice() {
    super.printInvoice('Mensalidade');
  }

  public abrirDetalhesMatricula(): void {
    if (!this.matriculaPermissions.visualizar) {
      this.toastr.warning('Você não tem permissão para Visualizar a matrícula.', 'Atenção');
      return;
    }

    const matricula = { id: this.item.matriculaId, plano: {} as Plano } as Matricula;

    this.modal.hide();

    this.modalService.show(MatriculaDetalhesComponent, {
      animated: true,
      class: 'modal-lg',
      backdrop: 'static',
      initialState: {
        item: matricula
      }
    });
  }

}
