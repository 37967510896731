import { Injectable, OnInit } from '@angular/core';
import { UserService } from '../../../user/shared';


@Injectable({
  providedIn: 'root'
})
export class PermissaoService {  
  constructor(
    private userService: UserService,
  ) { }

  public temPermissaoComissao(): boolean {
    return this.userService.usuarioLogado?.mostrarPainelInstrutorComissao && this.userService.usuarioLogado?.mostrarPainelInstrutor;
  }

  public temPermissaoAulas(): boolean {
    return this.userService.usuarioLogado?.mostrarPainelInstrutorAulas && this.userService.usuarioLogado?.mostrarPainelInstrutor;
  }

  public mostrarMenu(): boolean {
    return this.userService.usuarioLogado?.mostrarPainelInstrutor && (this.temPermissaoAulas() || this.temPermissaoComissao());
  }

  public idUsuarioLogado(): number {
    return this.userService.usuarioLogado.id;
  }
}