import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../shared';
import { Registro } from '../shared/models';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.scss']
})
export class RecuperarSenhaComponent implements OnInit {
  @BlockUI() blockui: NgBlockUI;
  public formGroup: UntypedFormGroup;
  private submitted: boolean;
  public confirmMessage: boolean = false;

  constructor(
    private service: AuthService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.formGroup = new UntypedFormBuilder().group({
      email: ['', [Validators.required, Validators.email]],
      gRecaptcha: ['', Validators.required]
    });
  }

  public onSubmitFormGroup(): void {
    this.submitted = true;
    this.blockui.start();

    const entity = {} as Registro;
    entity.email = this.formGroup.get('email').value;

    this.service.recuperarSenha(entity, this.formGroup.get('gRecaptcha').value)
    .pipe(finalize(this.blockui.stop))
    .subscribe(() => {
        // TODO: Criar uma tela nova somente com uma mensagem dizendo que foi enviado um codigo para email
        // this.router.navigate(['/', 'auth', 'login']);
      this.confirmMessage = true;
      },

      error => {
        this.toastrService.error(error.message, 'Error');
      }
    );

    this.blockui.stop();
  }

  public validateField(field: string, nested?: boolean, parentControl?: string) {
    if (nested) {
        return this.formGroup['controls'][parentControl]['controls'][field].invalid &&
          (this.formGroup['controls'][parentControl]['controls'][field].touched || this.submitted);
    }

    return this.formGroup.controls[field].invalid && (this.formGroup.controls[field].touched || this.submitted);
  }

}
